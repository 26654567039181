.reply-preview-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.84);

  display: flex;
  flex-direction: column;
}

.reply-action-container {
  width: 100%;
  height: 50px;
  position: relative;
  z-index: 1;
  padding: 10px
}

.reply-preview-modal-content {
  display: flex;
  justify-content: center;
  height: 100%;

  /* Fix vertical flex overflow */
  min-height: 0;

  position: relative;
}

.reply-preview-modal-footer {
  height: 60px;
}

.react-pdf-root .react-pdf__Document {
  height: 100%;
}

.react-pdf-root .react-pdf__Document .react-pdf__Page {
  height: 100%;
}
