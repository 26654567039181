.Conversation-Header {
  min-height: 50px;
  position: relative;
  border-bottom: solid 1px #f7f8f9;
  display: flex;
  padding: 5px;
}

.Conversation-Header .header-img {
  /* position: absolute; */
  /* left: 14px; */
  width: 35px;
  height: 35px;
  vertical-align: middle;
  border-radius: 10px !important;
  /* margin-right: 5px; */
}

.Header-Details {
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.Header-Details > :last-child {
  margin-left: auto;
  height: 47.5px;
}

.Patient-Details {
  display: flex;
  flex-wrap: wrap;
}

.Image-Container {
  height: 47.5px;
  width: 47.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.Conversation-Header .Setting-Container {
  /* position: absolute;
    width: 50px;
    height: 100%;
    top: 0;
    right: 0; */
  /* position: absolute;
    height: 100%;
    top: 50%;
    right: 0; */
}

.ddi-custom {
  padding: 5px 10px !important;
  color: #5c5b5b !important;
  font-size: 14px;
}

.Conversation-Container {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
}

.Conversation-Body {
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;

  /* border: 5px solid cyan; */
}

.side-tab {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: hidden;
  z-index: 2;
}

.side-tab,
.side-tab-empty {
  /* width: 350px; */
  height: 100%;
  max-height: calc(100vh - 148px);
  border-left: solid 1px #e5e5e5;
  overflow-y: auto;
  background: #ffffff;
}

.side-tab-empty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.side-tab-mobile {
  display: none;
}

#cards-list-container {
  min-width: 344px;
  width: 344px;
  max-height: calc(100vh - 145px);
  overflow-x: hidden;
  overflow-y: auto;
  transition: min-width 0.5s, width 0.5s;
  /* margin-top: 33px; */
}

.lists-side-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 196px);
}

#cards-list-details {
  width: 344px;
  position: relative;
  border-left: solid 1px #e5e5e5;
  overflow-y: auto;
  overflow-x: hidden;
}

.cards-list-details-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px !important;
  color: #2095f3;
  /* position: fixed; */
  width: 344px;
  background: #ffffff;
  height: 40px;
  padding: 5px;
  border-bottom: solid 1px #e5e5e5;
  z-index: 1;
}

.cards-list-details-header > span {
  font-weight: bold;
}

.cards-list-details-header > div {
  height: 10px;
  width: 10px;
}

.card-details-container {
  padding: 10px 10px 0px 10px;
}

@media (max-width: 1154px) {
  .side-tab {
    right: 0;
    position: absolute;
    width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 0.5s;
  }

  .side-tab-mobile {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    right: 5px;
    top: 35px;
    background: #ffffff;
    border-radius: 6px;
    padding: 2px 6px;
    font-size: 10px;
    border: solid 1px #d4d4d4;
    box-shadow: 0px 0px 3px 0px #d4d4d4;
    color: #2194f3;
    cursor: pointer;
  }

  .side-tab-mobile > *:not(:last-child),
  .side-tab-mobile-close > *:not(:last-child) {
    margin-right: 5px;
  }

  .side-tab-mobile-close-container {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    right: 5px;
    cursor: pointer;
  }

  .side-tab-mobile-close {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background: #ffffff;
    border-radius: 6px;
    padding: 2px 6px;
    font-size: 10px;
    border: solid 1px #d4d4d4;
    box-shadow: 0px 0px 3px 0px #d4d4d4;
    color: #2194f3;
    cursor: pointer;
  }
}

.em-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  width: 100%;
  font-size: 12px;
}

.card-container {
  margin: 5px;
  border: solid 1px #e5e5e5;
  min-width: 334px;
  border-radius: 8px;
}

.card-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-title-container .card-title,
.card-item-2-details i {
  color: #2095f3;
}

.card-item-container {
  height: 90px;
  transition: height 0.5s;
  overflow-y: hidden;
}
.card-title-container button,
.card-item-container-2 button {
  border-radius: 7px;
}

.card-title-container:hover > span {
  text-decoration: underline;
}

.card-title-container > span:hover {
  cursor: default;
}

.card-item-container-2 {
  /* height: 180px; */
  transition: height 0.5s;
  overflow-y: hidden;
}

.card-custom {
  padding: 10px !important;
}

.card-custom hr {
  margin: 10px 0px;
}

.card-custom > *:first-child span {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}

.card-custom > *:first-child button {
  padding: 3px 4px;
  line-height: 1;
  font-size: 10px;
}

.card-item-problem {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  color: #444444;
}

.card-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  color: #444444;
  /* height: 30px; */
  padding: 5px;
}

.card-item-grouped-list {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  color: #444444;
  /* height: 30px; */
  padding: 5px;
}

.card-item-grouped-list > *:first-child {
  margin: 5px 0px 10px 0px;
}

.card-item:hover,
.card-item-problem:hover,
.card-item-grouped-list:hover {
  background-color: #f1f1f1;
  border-radius: 8px;
  cursor: pointer;
}

.card-item-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  color: #444444;
}

/* .card-item-2 {
    height: 90px;
} */

.card-item > div {
  max-width: 260px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.card-item-problem > div,
.card-item-grouped-list > div {
  max-width: 260px;
  width: 100%;
  white-space: pre-wrap;
  cursor: pointer;
  font-size: 14px;
  padding: 4px;
}

.card-item-grouped-list > * {
  font-size: 14px;
}

.card-item-2 {
  position: relative;
  width: 100%;
  white-space: column;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 5px 5px 10px 5px;
  border: 1px solid #e5e5e5;
}

.card-item > *,
.card-item-2 > * {
  font-size: 14px;
}

.card-item-2 > *:first-child {
  position: absolute;
  text-align: right;
  width: 100%;
  max-width: 295px;
}

.card-item-2 > *:last-child {
  text-align: right;
  width: 100%;
}

.card-item-2 > *:last-child button {
  padding: 4px 10px;
  line-height: 1;
  font-size: 11px;
}

.card-item-2 > *:nth-child(2) > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.card-item-2-details {
  padding-top: 20px;
  width: 100%;
}

.card-item-2-details > div > *:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.card-item-2-details > div > *:last-child {
  max-width: 255px;
  width: 100%;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  white-space: row;
}

.doc-thumbnails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.doc-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 10px;
  margin: 5px;
  border: 1px solid #ececec !important;
  box-shadow: 0px 4px 7px -4px #888888;
  overflow: hidden;
}

.doc-item,
.doc-item .react-pdf__Document,
.doc-item .react-pdf__Page {
  min-height: 90px;
  min-width: 90px;
  max-height: 90px;
  max-width: 90px;
}

.doc-item .react-pdf__Page__canvas {
  max-height: 90px !important;
  max-width: 90px !important;
  border: 1px solid #dedede;
  border-radius: 10px;
}

.show-more {
  text-align: center;
  line-height: 0;
}

.show-more a {
  padding: 0;
  line-height: 0.8;
}

/* Chat box */
.Bubble-Container {
  width: 100%;
  position: absolute;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 7px;
  margin: 0;
  padding-top: 10px;
}

.Bubble-Container .bubble {
  max-width: 380px;
  word-wrap: break-word;
  /* line-height: 16px; */
  line-height: 1 !important;
  /* white-space: pre-line; */
  min-height: 32px;
}

.Bubble-List-Container {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 10px;
}

.Bubble-List-Container ._system-message {
  color: rgba(16, 25, 29, 0.42);
  font-size: 11px;
  margin: 0px 12px;
  line-height: 12px;
  padding: 8px 0;
  margin: 0 auto;
}

.Bubble-List-Container ._time-message {
  color: rgba(0, 0, 0, 0.42);
  font-size: 12px;
  margin: 0px 12px;
  line-height: 20px;
  padding: 8px 0;
  margin: 0 auto;
  font-weight: 600;
}

.Bubble-List-Container ._them-container {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.Bubble-List-Container .them-name {
  font-size: 11px;
  margin: 3px 3px 0 8px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  line-height: 12px;
}

.Bubble-List-Container .them-time {
  font-size: 11px;
  margin-left: 60px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  line-height: 14px;
}

._them-container ._avatar-column {
  height: auto;
  display: flex;
  flex-flow: column nowrap;
}

._them-container ._them-column {
  width: 100%;
  height: auto;
}

.speech-menu-container {
  display: none;
  height: 100%;
  /* margin: 0 auto; */
  position: relative;
}

.speech-menu {
  position: absolute;
  top: 40%;
  margin-left: 10px;
  transform: translateY(-50%);
  color: #0000008a;
  cursor: pointer;
}

.speech-menu-2 {
  position: absolute;
  top: 40%;
  margin-top: 15px;
  margin-left: 10px;
  transform: translateY(-50%);
  color: #0000008a;
  cursor: pointer;
}

._them-column:hover .speech-menu-container {
  display: inline-block;
}

._them-container .sC {
  width: 100%;
  height: auto;
}

._avatar-column .acR {
  width: 32px;
  display: flex;
  flex: 1;
}

._avatar-column .avatar {
  width: 32px;
  height: 32px;
  /* position: fixed; */
  margin-right: 6px;
  margin-left: 6px;

  display: block;
  /* z-index: 5; */
  /* bottom: -50px; */
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

._avatar-column .avatar img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

._them-column .from-them {
  position: relative;
  padding: 7px 18px;
  background-color: #f2f4ff;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: #000000db;
  float: left;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}
/*
.from-them:active {
    background-color: rgba(223, 226, 241, 0.98);;
}


.from-them:active:before {
    border-color: rgba(223, 226, 241, 0.98);;
} */

._them-column .from-them-continues {
  position: relative;
  padding: 7px 18px;
  background-color: #f2f4ff;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: #000000db;
  float: left;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

/* .from-them-continues:active {
    background-color: rgba(223, 226, 241, 0.98);;
}


.from-them-continues:active:before {
    border-color: rgba(223, 226, 241, 0.98);;
} */

._them-column .from-them-attention {
  position: relative;
  padding: 7px 18px;
  background-color: #fcebb6;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: black;
  float: left;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

/* .from-them-attention:active {
    background-color: #f4c567;
}


.from-them-attention:active:before {
    border-color: #f4c567;
} */

.from-them-attention:before {
  /* content:"";
    position:absolute;
    z-index:0;
    bottom:-2px;
    left:-7px;
    height:18px;
    border-left:20px solid #fcebb6;
    -moz-border-radius-bottomright: 16px 14px;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform:translate(0, -2px);
    -moz-transform:translate(0, -2px);
    transform:translate(0, -2px); */
}

.from-them-attention:after {
  /* content:"";
    position:absolute;
    z-index:3;
    bottom:-2px;
    left:4px;
    width:26px;
    height:20px;
    background:white;
    -moz-border-radius-bottomright: 10px;
    border-bottom-right-radius: 10px;
    -webkit-transform:translate(-30px, -2px);
    -moz-transform:translate(-30px, -2px);
    transform:translate(-30px, -2px); */
}

._them-column .from-them-attention-continues {
  position: relative;
  padding: 7px 18px;
  background-color: #fcebb6;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: black;
  float: left;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

/* .from-them-attention-continues:active {
    background-color: #f4c567;
}


.from-them-attention-continues:active:before {
    border-color: #f4c567;
} */

._them-column .from-them-urgent {
  position: relative;
  padding: 7px 18px;
  background-color: #f5c6cb;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: #721c24;
  float: left;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

/* .from-them-urgent:active {
    background-color: #db5c64;
}


.from-them-urgent:active:before {
    border-color: #db5c64;
} */

.from-them-urgent:before {
  /* content:"";
    position:absolute;
    z-index:0;
    bottom:-2px;
    left:-7px;
    height:18px;
    border-left:20px solid #f5c6cb;
    -moz-border-radius-bottomright: 16px 14px;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform:translate(0, -2px);
    -moz-transform:translate(0, -2px);
    transform:translate(0, -2px); */
}

.from-them-urgent:after {
  /* content:"";
    position:absolute;
    z-index:3;
    bottom:-2px;
    left:4px;
    width:26px;
    height:20px;
    background:white;
    -moz-border-radius-bottomright: 10px;
    border-bottom-right-radius: 10px;
    -webkit-transform:translate(-30px, -2px);
    -moz-transform:translate(-30px, -2px);
    transform:translate(-30px, -2px); */
}

._them-column .from-them-urgent-continues {
  position: relative;
  padding: 7px 18px;
  background-color: #f5c6cb;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: #721c24;
  float: left;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}
/*
.from-them-urgent-continues:active {
    background-color: #db5c64;
}


.from-them-urgent-continues:active:before {
    border-color: #db5c64;
}
 */

._them-column .from-them-forwarded {
  position: relative;
  padding: 7px 18px;
  background-color: rgb(149, 80, 179);
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: white;
  float: left;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

/* .from-them-forwarded:active {
    background-color: #db5c64;
}


.from-them-forwarded:active:before {
    border-color: #db5c64;
} */

.from-them-forwarded:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -2px;
  left: -7px;
  height: 18px;
  border-left: 20px solid rgb(149, 80, 179);
  -moz-border-radius-bottomright: 16px 14px;
  border-bottom-right-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
  -moz-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.from-them-forwarded:after {
  content: "";
  position: absolute;
  z-index: 3;
  bottom: -2px;
  left: 4px;
  width: 26px;
  height: 20px;
  background: white;
  -moz-border-radius-bottomright: 10px;
  border-bottom-right-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
  -moz-transform: translate(-30px, -2px);
  transform: translate(-30px, -2px);
}

._them-column .from-them-forwarded-continues {
  position: relative;
  padding: 7px 18px;
  background-color: rgb(149, 80, 179);
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: white;
  float: left;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

.from-me {
  position: relative;
  padding: 7px 18px;
  color: #000000db;
  background: #cae9ff;
  border-radius: 15px;
  float: right;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

/* .from-me:active {
    background-color: #3ba6ed;
}


.from-me:active:before {
    border-color: #3ba6ed;
} */

.from-me:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -2px;
  right: -7px;
  height: 18px;
  border-right: 20px solid #cae9ff;
  border-bottom-left-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
  -moz-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.from-me:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -2px;
  right: -56px;
  width: 26px;
  height: 20px;
  border: 0px;
  background: white;
  border-bottom-left-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
  -moz-transform: translate(-30px, -2px);
  transform: translate(-30px, -2px);
}

.from-me-continues {
  position: relative;
  padding: 7px 18px;
  color: #000000db;
  background: #cae9ff;
  border-radius: 15px;
  float: right;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

.from-me-forwarded {
  position: relative;
  padding: 7px 18px;
  color: white;
  background: rgb(149, 80, 179);
  border-radius: 15px;
  float: right;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

.from-me-forwarded:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -2px;
  right: -7px;
  height: 18px;
  border-right: 20px solid rgb(149, 80, 179);
  border-bottom-left-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
  -moz-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.from-me-forwarded:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -2px;
  right: -56px;
  width: 26px;
  height: 20px;
  border: 0px;
  background: white;
  border-bottom-left-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
  -moz-transform: translate(-30px, -2px);
  transform: translate(-30px, -2px);
}

.from-me-forwarded-continues {
  position: relative;
  padding: 7px 18px;
  color: white;
  background: rgb(149, 80, 179);
  border-radius: 15px;
  float: right;
  /*font-family: "Helvetica Neue";*/
  font-family: arial, sans-serif;
  font-size: 14px;
}

.Bubble-List-Container ._me-container {
  height: 100%;
  display: flex;
  flex-flow: row-reverse wrap;
  padding-right: 10px;
}

.Bubble-List-Container .me-name {
  font-size: 11px;
  margin: 5px 8px 0 3px;
  display: flex;
  flex-flow: row-reverse wrap;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  line-height: 12px;
}

.Bubble-List-Container .me-time {
  font-size: 11px;
  display: flex;
  flex-flow: row-reverse wrap;
  margin-right: 60px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  line-height: 11px;
}

._me-container ._avatar-column {
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  float: right;
  display: none;
}
/*
._me-container ._me-column {
    height: auto;
} */

._me-container:hover .speech-menu-container {
  display: inline-block;
}

._avatar-column-seen .avatar {
  width: 18px;
  height: 18px;
  /* position: fixed; */
  margin-right: 2px;
  margin-left: 2px;
  display: block;
  z-index: 5;
  /* bottom: -50px; */
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

._avatar-column-seen .avatar img {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.Thread-Name {
  white-space: nowrap;
  /* padding-right: 40px; */
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  /* width: 100%; */
  margin: 0;
  /* padding-top: 5px; */
  /* text-align: center; */
  font-weight: 500;
  /* display: inline; */
}

/**
* Loading
*/

.lds-ring {
  display: inline-block;
  position: relative;
  /* height: 64px; */
  margin: 0 auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 31px;
  height: 31px;
  margin: 6px;
  border: 4px solid #2194f3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2194f3 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ringe div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.textarea-style {
  max-height: 128px;
  resize: none;
  border: none;
  font-size: 15px;
  width: 100%;
}

.textarea-style:focus {
  border: 0 none #fff;
  /* border-top: 1px solid rgba(0, 0, 0, 0.12); */
  outline: none;
}

.Conversation-Action-Menu {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  align-content: flex-start;
}

.Conversation-Action-Menu .item {
  margin-right: 5px;
  color: #908e8e;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Conversation-Action-Menu .item:active {
  color: #2194f3;
}

.Conversation-Action-Menu .send {
  position: absolute;
  right: 5px;
}

/* .Conversation-Action-Menu .active {
    color: #2194f3 !important;
} */

.Conversation-Action-Menu .item-tag {
  margin-right: 0.5rem;
  color: #908e8e;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
}

.Conversation-Action-Menu .item-tag:hover {
  color: #2194f3;
}

.Conversation-Action-Menu .item-attention {
  margin-right: 0.5rem;
  color: #908e8e;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
}

.Conversation-Action-Menu .item-attention:active {
  color: rgb(242, 188, 82);
}

.Conversation-Action-Menu .active-attention {
  color: rgb(242, 188, 82) !important;
}

.tooltip-inner {
  text-align: left;
  font-size: 11px;
}

.emoji-mart-search-icon {
  top: 6px;
}

.selected-attention-chips {
  display: inline-block;
  background-color: rgb(33, 150, 243);
  color: white;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  padding: 0 6px;
  margin-right: 5px;
}

.selected-attention-close-chips {
  cursor: pointer;
  float: right;
  font-size: 14px;
  line-height: 23px;
  padding-left: 8px;
}

.selected-attention-close-chips:hover {
  color: rgba(0, 0, 0, 0.18) !important;
}

.Setting-Button {
  font-size: 25px;
  color: #908e8e;
  cursor: pointer;
  background: white;
  /* display: inline; */
}

.Setting-Button:active {
  color: #2194f3;
}

.Setting-Button:hover {
  color: #2194f3 !important;
}

.ddi-custom:hover {
  cursor: pointer;
}

.Attention-Container {
  position: relative;
  /* min-height: 75px; */
  height: 80px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
  overflow-x: auto;
  display: flex;
  /* align-items: center; */
}

.Scroll-Chips {
  position: absolute;
  width: 100%;
  height: fit-content;
  padding-top: 0px;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  bottom: 0px;
}

.Attention-Container .Urgent-Button {
  color: #908e8e;
  position: relative;
  cursor: pointer;
}

.Image-Preview-Container {
  display: flex;

  min-height: 130px;
  padding-top: 15px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
  padding-left: 20px;
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
}

.Image-Preview-Container .selected-image {
  height: 100px;
  max-width: 200px;
  overflow: hidden;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.Image-Preview-Container .selected-image-container {
  display: inline-block;
  position: relative;
  margin-right: 0.5rem;
}

.Image-Preview-Container .selected-image-container:hover .remove-selected-image {
  display: block;
}

.remove-selected-image {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  display: none;
  cursor: pointer;
}

.Conversation-Header .CCM-Button {
  font-size: 14px;
  font-weight: bold;
  background: rgb(149, 80, 179);
  color: white;
  border-radius: 6px;
}

.Conversation-Header .CCM-Button:active {
  background: rgba(149, 80, 179, 0.88);
}

.react-pdf__Page-canvas-container {
  max-width: 300px;
}

.react-pdf__Page-canvas-container .react-pdf__Page-canvas {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page-canvas-container .react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page-canvas-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page-canvas-container div {
  display: none;
}

/* For tooltip */

.react-pdf__Page-canvas-container-tooltip {
  /* max-width: 300px; */
}

.react-pdf__Page-canvas-container-tooltip .react-pdf__Page-canvas {
  height: auto !important;
  width: auto !important;
  max-width: 400px;
  max-height: 600px;
}

.react-pdf__Page-canvas-container-tooltip .react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page-canvas-container-tooltip canvas {
  height: auto !important;
  width: auto !important;
  max-width: 400px;
  max-height: 600px;
}

.react-pdf__Page-canvas-container-tooltip div {
  display: none;
}

.Picture-Info-Container {
  display: block;
  position: absolute;
  top: 7px;
  right: 5px;
  width: 14px;
  height: 13px;
  background: white;
  border-radius: 53%;
  z-index: 10;
}

.Picture-Info {
  color: rgb(33, 148, 243);
  font-size: 15px;
  position: relative;
  top: -1px;
  left: -1px;
}

.hc-online-badge {
  position: absolute;
  right: 8px;
  top: 6px;
  background: #2194f3;
  color: white;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-border-radius: 5;
  padding: 0.3rem;
  border: 1px solid white;
}

.facesheet-link {
  padding-left: 3px;
  padding-right: 3px;
}

.facesheet-link:hover {
  background: #2194f3;
  text-decoration: none !important;
  border-radius: 5px;
}

.facesheet-link:hover span {
  color: white !important;
}

.acr-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.select-send {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;
  height: 80px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #2095f3;
  z-index: 1;
}

.custom-send {
  background: #ffffff !important;
  border-radius: 20px !important;
}

.custom-send:hover {
  background: #e5e5e5 !important;
}

.custom-menu.show {
  min-height: 350px !important;
  max-height: 350px !important;
  min-width: 300px !important;
  max-width: 300px !important;
  left: -150px;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.forms-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 105px;
  width: 82px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.form-item:hover {
  background: #f0f0f0;
  cursor: pointer;
}

.more-d {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.more-d > *:first-child {
  margin-right: 3px;
}

.more-d > * {
  color: #ffffff;
  font-size: 10px !important;
  font-weight: normal !important;
}

.badge-notif {
  font-size: 9px !important;
  padding: 5px 6px !important;
  text-decoration: none !important;
  margin-left: auto;
  margin-right: 5px;
}

#action-items-button {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 120px;
}

#card-item-container-id {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: #ffffff;
  width: 120px;
  height: 0px;
  border-radius: 15px;
  overflow: hidden;
  height: 31px;
}

#action-items-title {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  height: 31px;
  width: 320px;
  right: 10px;
  bottom: 15px;
  background: #2194f3;
  border-radius: 15px;
  color: #ffffff;
  width: 100%;
  padding: 0 10px;
  font-size: 14px;
}

.copyOpen {
  position: absolute;
  width: 97px !important;
  right: 3px;
  visibility: hidden;
}

.card-item:hover > .copyOpen {
  visibility: visible !important;
  background: #f1f1f1;
}

.card-item button {
  font-size: 11px !important;
  height: 30px !important;
}

@media (max-width: 969px) {
  .mobile-view {
    display: unset;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .web-view {
    display: none;
  }
}

@media (max-width: 870px) {
  .m-cls-50 {
    margin-left: 34px !important;
  }
}

@media (min-width: 970px) {
  .mobile-view {
    display: none;
  }

  .web-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: end;
    margin-left: auto;
  }
}

@media (min-width: 1080px) {
  .web-view {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    justify-content: end;
    margin-left: auto;
  }
}

.arrow-keys-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: inherit;
  height: 0;
  top: 25%;
  padding: 10px 10px;
  pointer-events: none;
}

.arrow-key-left,
.arrow-key-right {
  display: none;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.4);
  width: 20px;
  height: 40px;
  border-radius: 5px;
  pointer-events: all !important;
}

.arrow-key-left:hover,
.arrow-key-right:hover {
  cursor: pointer;
}

.cards-btn-cls {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
}

.new-cards-cls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 5px;
  background: #ffffff;
  border-radius: 6px;
  padding: 2px 6px;
  font-size: 10px;
  border: solid 1px #d4d4d4;
  box-shadow: 0px 0px 3px 0px #d4d4d4;
  color: #2194f3;
  cursor: pointer;
  width: 90px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}

[contenteditable="true"] {
  outline: 0px solid transparent;
  font-size: 14px;
}

/* .input-container {
  position: relative;
  width: 300px;
  border: 1px solid #000;
}*/

.content-editable {
  width: 100%;
  height: 100px;
  padding: 10px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  outline: none;
}

.highlight {
  color: #2193f3;
  font-weight: bold;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.threadDatePicker {
  width: 125px;
  margin: 0 5px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.calendar-select {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 40%;
  top: 45px;
  background: #ffffff;
  border-radius: 6px;
  padding: 2px 6px;
  font-size: 10px;
  border: solid 1px #d4d4d4;
  box-shadow: 0px 0px 3px 0px #d4d4d4;
  color: #2194f3;
  cursor: pointer;
  z-index: 3;
}

.upto-notice {
  padding: 0px !important;
  /* opacity: 0; */
}

.upto-notice > button > span {
  font-size: 12px;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  font-size: 20px;
  line-height: 40px;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 300px;
}

.edit-message-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 45px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #2095f3;
  z-index: 1;
}

.custom-edit {
  background: #ffffff !important;
  border-radius: 20px !important;
  color: #2094f3 !important;
}

.Convo-Search-Container input {
  padding: 3px 30px 3px 35px;
  color: black;
  font-size: 13px;
  background: #f6f7f9;
  /* border: 1px solid #ececec; */
  border-radius: 9px;
  margin: 0px;
  width: 100% !important;
  outline: none;
  height: 24px;
}

.Convo-Search-Container input::placeholder {
  color: #bcc5d1;
}

.Convo-Search-Container {
  position: relative;
  width: 100%;
  margin-right: 12px;
}

.Convo-Search-Container i {
  position: absolute;
  left: 10px;
  color: #bcc5d1;
  font-size: small;
  top: 6px;
}

.Convo-Filter-Container {
  border-bottom: solid 1px #f7f8f9;
  padding: 4px 10px;
  display: flex;
  max-height: 34px; /* Adjust based on content */
  height: 100%;
  transition: height 0.5s ease-out;
}

.hidden {
  height: 0px;
  padding: 0px 40px;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: top;
}
