.main-wrapper-files {
  height: 100vh;
  /* overflow-y: auto !important; */
  position: relative;
}

.thumbnail-wrapper-files {
  background: #0000000a;
  position: relative;
  height: 175px;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-thumbnail-files {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.file-thumbnail-caption {
  display: block;
  margin: 0 auto;
  line-height: 1.2;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overlay {
  position: absolute;
  top: 25%;
  bottom: 0;
  /* left: 0; */
  right: 25%;
  height: 40%;
  width: 50%;
  opacity: 0;
  transition: .25s ease;
  background-color: #0000009c;
  border-radius: .25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.overlay-tagg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 20%;
  width: 20%;
  transition: .25s ease;
  /* border-radius: 20px; */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  z-index: 1;
}

.overlay-tagg > div > i {
  color: #0000009c;
}

.overlay:hover,
.overlay-tagg:hover {
  text-decoration: none;
}

.thumbnail-wrapper-files:hover .overlay,
.overlay-tagg:hover {
  opacity: 1;
}

.overlay > *:not(:last-child),
.overlay-tagg > *:not(:last-child) {
  margin-right: 5px;
}

.overlay-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  color: white;
  font-weight: bold;
  font-size: 12px;
  height: 40px;
  width: 50px;
}

.overlay-item:hover,
.overlay-tagg {
  background: rgba(255,255,255, 0.3);
  /* border-radius: 5px; */
  color: #ffffff;
  text-decoration: none;
}

.main-row-wrapper {
  /* border: 5px solid green; */
  display: block;
  overflow-y: auto;
}

/* Style to change separator  */
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.document-item-container {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* overflow-y: auto; */
  margin: 15px;
  /* width: calc(100% - 65px); */
}

.document-item-container > *:not(:last-child) {
  margin-right: 15px;
}

.document-item {
  /* min-width: 175px;
  max-width: 175px; */
  margin-bottom: 15px;
}

.patient-doc-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.patient-doc-details > *:not(:last-child) {
  margin-right: 10px;
}