.contact-profile-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  height: 250px;
  width: 100%;

  background: #FFFFFF;
  overflow: hidden;
}

.contact-profile-banner span,
.contact-profile-banner img {
  z-index: 1;
}

.blur-container {
  background-color: lightgray;
  position: absolute;
  height: inherit;
  width: inherit;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(10px);
}

.contact-profile-name {
  font-size: 20px;
  color: #FFFFFF;
}

.contact-profile-description {
  font-size: 14px;
  color: #FFFFFF;
}

.img-cls {
  border-radius: 25% !important;
  width: 100%;
  height: 150px !important;
  max-width: 150px !important;
}

.profile-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  height: 30px;
  background: #7b7b7b;
  font-size: 12px;
}

.profile-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  min-height: 100px;
  border-bottom: 1px solid #e5e5e5;
}

.profile-buttons-container > * {
  margin: 15px 25px;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container button {
  font-size: 30px;
  border-radius: 50%;
  height: 63.5px;
}

.button-container span {
  color: #444444;
  font-size: 12px;
  margin-top: 10px;
}

.contact-profile-container {
  height: 100%;
  overflow-y: auto;
}
