/* The Modal (background) */
.File-Preview-Modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background-color: rgb(0,0,0); Fallback color */
    background-color: rgba(0, 0, 0, 0.84); /* Black w/ opacity */
  }

.File-Preview-Modal .Action-Container {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    /* background: #00000052; */
    z-index: 1;
    padding: 10px
}

.doc-action-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
  width: 100%;
}

.doc-action-container > *:last-child {
  margin-left: auto;
}

.doc-action-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: rgba(0,0,0, 0.4);
  height: 40px;
}

.doc-action-view > * {
  color: #ffffff;
}

.File-Preview-Modal .Thumbnail-Container {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    padding-top: 20px;

    text-align: center;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    /* background: #00000052; */
}
  /* Modal Content/Box */
  .File-Preview-Modal-Content2 {
    position: absolute;
    top: 50px;
    bottom: 100px;
    left: 0;
    right: 0;
  }

  /* The Close Button */
  .File-Preview-Close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .File-Preview-Close:hover,
  .File-Preview-Close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .Main-Image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* flex:0 1 auto; */
    align-self:center;
    height: 100%;
    /* border:1px solid red; */
    transition: transform 0.25s ease-in;
  }

  .Main-Video {
    background: black;
    max-height: calc(100vh - 170px);
    align-self:center;
    display: flex;
  }

  .Main-Video video:focus {
    outline: none !important;
  }

  .thumbnal {
    background-position: center;
    background-size: cover;
    border-radius: 2px;
    /* box-shadow: inset 0 0 0 1px hsla(0,0%,100%,.2); */
    cursor: pointer;
    display: inline-block;
    height: 50px;
    margin: 2px;
    overflow: hidden;
    width: 50px;
    active-border-color: white;
    size: 50px;
    gutter: 2px;
  }

  .Image-Overlay {
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
  }


  .File-Action-Button {
    background: transparent;
    color: white;
    font-size: 22px;
    cursor: pointer;
    height: 35px;
    margin: 0 10px;
  }

  .File-Action-Button:hover {
    color: #2194f3;
  }

  .File-Action-Button:disabled {
    color: #989595;
  }

.Previous-Button {
    background: rgba(0,0,0, 0.4);
    color: white;
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 55px;
    cursor: pointer;
    width: 50px;
    z-index: 1;
}

.Next-Button {
    background: rgba(0,0,0, 0.4);
    color: white;
    position: absolute;
    right: 0;
    top: 50%;
    font-size: 55px;
    cursor: pointer;
    width: 50px;
    z-index: 1;
}

/* .react-pdf__Page-canvas-container-file {
    max-height: calc(100vh - 150px);
} */

.Show-Info:hover ~ .Info-Message {
    display: block
}

.Info-Message {
    display: none;
    background: white;
    position: absolute;
    left: 5px;
    top: 60px;
    width: 305px;
    min-height: 70px;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
}


.Info-Arrow-Up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -7px;
    left: 24px;
}

.Category-Container {
  background: white;
  height: 100%;
  overflow-y: auto;
}

.File-Preview-Container {
  display: flex;
  justify-content: center;
  /* background: black; */
  overflow-y: auto;
  height: 100%;
}

.list-group-item.category {
  border: 1px solid #dadce0 !important;
  margin-bottom: 8px !important;
  margin-left: unset;
  margin-right: unset;
  box-shadow: unset;
  border-radius: 5px 5px 5px 5px;
}

.Primary-Image {
  transition: transform 0.25s ease-in;
}
