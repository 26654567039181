.call-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.camera-container2 {
  position: absolute;
}

.camera-container2 video {
  height: 100%;
}

.allow-message {
  display: block;
  background: white;
  position: absolute;
  right: 2px;
  top: 10px;
  width: 305px;
  min-height: 70px;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -7px;
  right: 7px;
}

.call-image-profile {
  width: 75px;
  height: 75px;
  display: block;
  border-radius: 50%;
  margin: 5px 5px 0 5px;
  display: inline;
}

.other-profile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: white;
}

.control-container {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  color: white;
  text-align: center;
  z-index: 99999;
}

.remote-camera-container {
  height: 100%;
}

.remote-camera-container video {
  height: 100%;
  margin: 0 auto;
  display: block;
}

.control-container span {
  display: block;
  font-size: 12px;
}

.status-container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 120px;
  font-size: 20px;
  color: white;
}

.camera-container {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--dark);
}

.camera-container video {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 100% !important;
  max-width: 100%;
}

.camera-container div {
  background-color: black;
  height: 100% !important;
  display: flex;
  justify-content: center;
}

.video-container {
  position: relative;
  background-color: black;
  flex: 1;
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(50vw, 1fr));
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
  .video-container {
    grid-template-columns: repeat( auto-fill, minmax(50vw, 1fr));
  }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .video-container {
    grid-template-columns: repeat( auto-fill, minmax(50vw, 1fr));
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .video-container {
    grid-template-columns: repeat( auto-fill, minmax(33.33vw, 1fr));
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .video-container {
    grid-template-columns: repeat( auto-fill, minmax(33.33vw, 1fr));
  }
}

.video-item {
  max-height: 50vh;
  position: relative;
  overflow: hidden;
}

.user-thumbnail {
  z-index: 3;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.user-thumbnail img {
  width: 50px;
  height: 50px;
  background-color: white;
}

.user-thumbnail .participant-name {
  color: white;
  background: #908e8e4a;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.camera-placeholder {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.screen-share-placeholder {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.camera-placeholder .img {
  /* transform: scale(0.25);
  width: 100%; */
  height: 100%;
  background-color: black;
}

.camera-placeholder .bg {
  z-index: -1;
  position: absolute;
  width: 100%;
  background-color: #263238;
  opacity: 0.2;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  font-size: 16px;
  line-height: 1.33;
  border-radius: 25px;
}

.controls-container {
  z-index: 4;
  width: 100%;
  bottom: 0px;
  padding: 5px;
  /* background-color: #03a9f4; */
  display: flex;
}

.video-flex-container {
  display: flex;
  flex-direction: column;
}

.connection-status {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: grey;
}

.connection-status.warning {
  background-color: #ff9800;
}

.connection-status.success {
  background-color: #00ff0a;
}

.connection-status.danger {
  background-color: #bd2130;
}

.connection-status.secondary {
  background-color: #6c757d;
}

.dominant-speaker {
  border: 3px solid #00ff0a;
}

.speaker-layout-container {
  background-color: black;
  flex: 1;
  width: 100%;
  /* Grid layout */
  display: grid;
  grid-template-rows: 3fr 1fr;
  grid-template-columns: auto;
  /* Grid spacing */
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  padding: 0.5rem;
}

.viewers-container {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  width: 100%;
  height: auto;
  overflow: auto;
}

.viewer-wrapper {
  height: 100%;
  width: 50%;
  margin-right: 0.5rem;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
  .speaker-layout-container {
    grid-template-rows: 3fr 1fr;
    grid-template-columns: auto;
  }
  .viewers-container {
    overflow-x: auto;
    width: 100%;
    height: auto;
  }
  .viewer-wrapper {
    height: 100%;
    width: 35%;
  }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .speaker-layout-container {
    grid-template-rows: 3fr 1fr;
    grid-template-columns: auto;
  }
  .viewers-container {
    overflow-x: auto;
    width: 100%;
    height: auto;
  }
  .viewer-wrapper {
    height: 100%;
    width: 35%;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .speaker-layout-container {
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto;
  }
  .viewers-container {
    display: block;
    overflow-y: auto;
    height: 100%;
    width: auto;
  }
  .viewer-wrapper {
    height: 20%;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.speaker-container {
  /* background-color: red; */
}

.video-item>div {
  height: 100%;
}

.screen-share-alert {
  position: absolute;
  background-color: #00ff0a;
}

.toast-container-custom {
  width: unset !important;
  min-width: 320px !important;
}

.toast-base {
  border-radius: 25px !important;
  min-height: unset !important;
  opacity: 0.8 !important;
}

.toast-base.custom {
  background-color: black;
  color: white;
}

.toast-base.prompt {
  opacity: 1 !important;
  border-radius: 0.5rem !important;
}

.toast-base-main {
  border-radius: 10px !important;
  box-shadow: 0px 0px 20px -3px #888888;
  padding: 15px;
}

.profile-image-wrapper-x {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-image-wrapper-y {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
}

.field-content {
  border-radius: 50%;
  width: 50%;
  padding-bottom: 50%;
  margin: 1em auto;
  overflow: hidden;
  position: relative;
  background: #000
}

.field-content img {
  position: absolute;
  width: auto;
  min-width: 100%;
  min-height: 100%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: cover;
  top: 50%;
}