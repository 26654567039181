
.mb-0 > button {
    /* display: block; */
    position: relative;
    width: 100%;
    text-align: left;
    text-decoration: none !important;
    color: black !important;
}
.mb-0 > button:after {
    content: "\f078"; /* fa-chevron-down */
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    color: #1e94f3;
    right: 10px;
}
.mb-0 > button[aria-expanded="true"]:after {
    content: "\f077"; /* fa-chevron-up */
}

.card-body {
    padding: 0px 0px 0px 1.25rem;
}

.list-icon {
    position: absolute;
    font-size: 16px;
    left: 24px;
    -webkit-transform: translate(2px, 10px);
    transform: translate(2px, 13px);
}



.Label-Nav-Container {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 50px;
}

.blur {
    width: 0;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #02020266;
    z-index: 9999;
}

.Label-Nav-Container a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.Label-Nav-Container a:hover {
    color: #f1f1f1;
}

.Label-Nav-Container .closebtn {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 25px;
    margin-left: 50px;
}

.label-item {
    text-decoration: none !important;
    color: black;
}

.label-item:hover {
    color: #1e94f3;
}

.form-bypass .form-group {
    margin-bottom: 5px;
}

.label-cls {
    font-size: 10px;
    margin-bottom: 0px;
    color: #444444;
}

.is-pending {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0px;
}

.is-pending > span {
    font-size: 10px;
    margin-right: 5px;
}