.pn-container {
  height: 100%;
  width: 100%;
}

.pn-container > * {
  margin-bottom: 15px;
}

.pn-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pn-header > :first-child {
  font-size: 30px;
}

.pn-header > :not(:first-child) {
  font-size: 14px;
}

.custom-card {
  padding-left: 0px;
}

.custom-card,
.custom-card:hover {
  box-shadow: unset;
}

.plan-inputs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.plan-inputs-container > .dropdown-toggle {
  margin-left: auto;
  margin-right: 5px;
}

.bypass-dropdown .plan-inputs-container .cust-width.show {
  display: flex;
  flex-wrap: wrap;
  min-width: 600px !important;
  max-width: 600px !important;
  height: 300px;
}

.plan-inputs-container .cust-width.show {
  display: flex;
  flex-wrap: wrap;
  min-width: 900px !important;
  max-width: 900px !important;
}

.cust-typeahead .dropdown-menu.show {
  display: flex;
  flex-wrap: wrap;
  min-width: 450px !important;
  max-width: 450px !important;
  height: 150px;
}

.plan-inputs-container .dropdown-menu {
  box-shadow: 0px 0px 4px 1px #afafaf !important;
  overflow: auto;
  max-height: 500px;
}

.bypass-dropdown .card-dropdown-list {
  display: inline-block;
  width: 100%;
  max-width: 270px;
  margin: 5px 10px 10px 10px;
}

.bypass-dropdown .custom-card {
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  overflow: hidden;
}

.bypass-dropdown .card-body {
  max-height: 598px;
  overflow-y: auto;
}

.card-dropdown-list {
  display: inline-block;
  width: 100%;
  max-width: 275px;
  margin: 5px 10px 10px 10px;
}

.card-dropdown-list > span {
  font-size: 18px;
  color: #2194f3;
}

.dropdown-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid #e5e5e5;
}

.dropdown-title {
  margin: 10px;
}

.custom-form-group-container,
.custom-form-group-container-2 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.custom-form-group-container {
  flex-wrap: wrap;
}
.custom-form-group-container-2 {
  margin: 5px;
  flex-wrap: nowrap;
}

.custom-form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5px 5px 10px 5px;
  width: 100%;
  max-width: 150px;
}

.plan-inputs-container .custom-form-group {
  margin: 5px;
}

.custom-form-group,
.custom-form-group input {
  font-size: 12px;
}

.custom-form-group span {
  font-size: 12px;
}

.custom-form-group input,
.custom-form-group select,
.assessment-item input,
.assessment-item textarea {
  font-size: 12px;
  border: solid 1px #d1d3e2;
  height: 28px;
}

.assessment-item input {
  margin-bottom: 5px;
}

.custom-textarea {
  border: solid 1px #d1d3e2 !important;
}

.textarea-container {
  position: relative;
  margin: 0px 5px 5px 5px;
  width: 100%;
}

.txt-clr:hover i,
.textarea-container:hover i {
  display: unset;
}

.custom-form-group-container .clear-textarea {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 6px;
  display: none;
  font-size: 12px;
}

.textarea-container .clear-textarea {
  cursor: pointer;
  position: absolute;
  top: 29px;
  right: 6px;
  display: none;
}

.plan-textarea-container {
  margin: 5px;
  position: relative;
}

.textarea-container *,
.textarea-container textarea,
.plan-textarea-container textarea,
.assessment-item span,
.assessment-item input,
.assessment-item textarea {
  font-size: 12px;
  color: #444444;
}

.assessment-item > *:last-child {
  width: 100%;
}

.two-columns {
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  margin-right: -10px;
}

.two-columns > div {
  width: 50%;
  margin: 0px 10px;
}

.assessment-item {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.assessment-item > span {
  width: 25px;
}

.assessment-item > button {
  width: 25px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d3e2;
  border-radius: 5px;
  margin-right: 5px;
  cursor: move;
  cursor: -webkit-grabbing;
}

/* PLANS */

.plan-label-flex {
  display: flex !important;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.checkbox-menu li label {
  display: block;
  padding: 3px 10px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  margin: 0;
  transition: background-color 0.4s ease;
}
.checkbox-menu li input {
  margin: 0px 5px;
  top: 2px;
  position: relative;
  height: 14px;
  outline: unset !important;
  -webkit-appearance: checkbox !important;
}

.checkbox-menu li.active label {
  background-color: #cbcbff;
  font-weight: bold;
}

.checkbox-menu li label:hover,
.checkbox-menu li label:focus {
  background-color: #f5f5f5;
}

.checkbox-menu li.active label:hover,
.checkbox-menu li.active label:focus {
  background-color: #b8b8ff;
}

.badges-container {
  margin: 0px 5px;
}

.cc-id button {
  margin: 5px 5px 5px 0px;
  font-size: 12px;
  height: 28px;
}

.cc-badge {
  line-height: 1.5 !important;
  padding: 5px 10px !important;
  margin: 5px;
}

.cc-badge i {
  margin-left: 5px;
}

.tab-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.attention-notify-box-container {
  border-radius: 10px;
  background: #ffffff;
}

.attention-notify-box {
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 320px;
  padding: 15px;
  position: "relative";
}

.assignment-notify-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20;
  top: 0;
  left: 0;
}

.save-changes-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.save-changes-box {
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;

  background: #ffffff;
  max-width: 450px;
  padding: 15px;
}

.save-changes-box > *:first-child {
  margin-right: 15px;
}

.save-changes-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cust-card-body {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.cust-card-body-2 {
  padding: 10px;
}

.form-group-buttons > *:first-child {
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

@media (max-width: 420px) {
  .bypass-dropdown .plan-inputs-container .cust-width.show {
    display: flex;
    flex-wrap: wrap;
    min-width: 300px !important;
    max-width: 300px !important;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .cust-card-body {
    flex-wrap: wrap;
  }

  .two-columns {
    flex-wrap: wrap;
  }

  .two-columns > div {
    width: 100%;
    margin: 0px 10px;
  }

  .two-columns > div:first-child {
    margin-bottom: 15px;
  }
}

@media (max-width: 925px) {
  .plan-inputs-container .cust-width.show {
    min-width: 450px !important;
    max-width: 450px !important;
  }
}

.for-orders .card-header {
  background: #ffffff;
  color: #2194f3;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.for-orders .custom-card {
  margin-bottom: 0px;
}

/* wysiwyg */

.custom-card .rdw-editor-wrapper {
  width: 100%;
  min-height: 500px;
}

.custom-card .rdw-editor-main {
  overflow: unset;
}

.custom-badge-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 16px !important;
  font-size: 10px !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  cursor: pointer;
  right: -7px;
  top: -7px;
}

/* form validity bypass */

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #d1d3e2 !important;
  background-image: unset !important;
  padding-right: 5px !important;
}

.plan-search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px 10px 5px;
}

.plan-search-container .rbt input {
  border: solid 1px #e5e5e5;
  height: 33px;
  font-size: 14px;
  border-radius: 5px;
}

.pn-image-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 180px;
  width: 150px;

  border: solid 1px #e5e5e5;
  border-radius: 5px;
  margin-right: 15px;
  overflow: hidden;
}

.pn-image-container > img {
  width: auto;
  height: auto;
  max-height: 180px;
  max-width: 150px;
}
