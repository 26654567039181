.btn-subs {
    color: #2194f3;
    border: 1;
    border-radius: 10px;
    height: 40px;
    border-color: grey;
    font-weight: bold;
    width: 200px;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-subs:hover {
    color: white !important;
    background-color: #2194f3 !important;
    border-color: #2194f3;
}

.subs-container {
    position: relative;
    padding: 10px 20px;
    background-color: white;
    border: 0;
    border-radius: 15px;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

.subs-container h3,
.subs-header h3 {
    color: black;
}

.subs-container h2 {
    color: #2194f3;
}

.subs-header p {
    color: black;
}

.subs-header p > :nth-child(2) {
    margin-bottom: 0px;
}

/* .subs-container div {
    display:"flex";
    justify-content: center;
} */

.ul-subs {
    list-style: none;
    margin-left: 0;
    padding-left: 2em;
    padding-top: 10px;
    text-indent: -1em;
}
  
.ul-subs li {
    padding-left: 1em;
    padding-bottom: 5px;
    text-indent: -1em;
}
  
.ul-subs li:before {
    /* //content: ''; */
    padding-right: 5px;
}
  
.ul-subs i {
    color: blue;
}
  
.ul-subs > :first-child,
.ul-subs > :nth-child(2) {
    color: black;
}

.members-img img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 5px;
    border: 2px solid #908e8e;
}

.members-img p {
    margin-bottom: 0px;
}

.members-badge {
    width: 90px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}