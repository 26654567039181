.sidebar {
  min-width: 14rem!important;
}

a.list-group-item.sidebar-item {
  margin: 0 !important;
  box-shadow: unset !important;
  border: unset !important;
  border-radius: unset !important;
  text-decoration: none;
  padding: 0.50rem 1.0rem;
}

.sidebar-row {
  padding: 0.50rem 1.0rem;
  width: inherit;
}

.hipaa-badge-image {
  max-width: 50px;
  max-height: 50px;
}

.shadow-top {
  box-shadow: 0 -6px 8px -3px rgba(58, 59, 69, 0.15);
}

.overflow-y-auto {
  overflow-y: auto;
}

.tel-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.dashboard-box {
  padding: 5px 0px;
  background-color: #FFFFFF;
  border-radius: 8px;
  width: 100%;
}

.dashboard-box > h6{
  padding: 8px 16px;
  margin-bottom: 0px;
  overflow: hidden;
}

.label-cont, .label-cont:hover {
  color: #000000;
}

.label-cont > :nth-child(2) {
  font-size: 14px;
  color: #808080;
}

.label-cont > :nth-child(3) {
  font-size: 15px;
}

.col-flex > * {
  margin-bottom: 20px;
}

.nav-bac {
  background-color: #ebf5ff;
}

.hc-nav-link-active {
  font-size: 14px;
    display: block;
    padding: 0.5rem 1rem;
    color: #808080;
    background-color: #FFFFFF;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-weight: bold;
}

.check-task {
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 4px !important;
  border: solid 2px #2194f3 !important;
  padding: 1px 4px !important;
  font-size: 12px !important;
}

.check-task > i {
  visibility: hidden;
}

.check-task:hover > i {
  visibility: visible !important;
}

.pre-check {
  margin-left: auto;
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 4px !important;
  padding: 1px 4px !important;
  border: solid 2px #2194f3 !important;
}

.line-bulb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bulb {
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
  background-color: #f7f7f7;
  margin-bottom: 8px;
  margin-top: 8px;
}

.v-line {
  border: solid 1px #f7f7f7;
  height: 100%;
  width: 3px;
  background-color: #f7f7f7;
}

.hover-bypass:hover {
  background-color: unset !important;
}

.hover-bypass:hover .telemed-card {
  background-color: #c8e6ff;
}

.hover-bypass:hover .bulb,
.hover-bypass:hover .v-line {
  background-color: #05aaf7;
}

.hover-bypass:hover .title-hov {
  color: #2194f3;
}

.telemed-card {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
}

.tel-time {
  font-size: 16px;
  color: #bababa;
}

.fa-position {
  position: absolute;
  right: 8px;
  top: calc(50% - 8px);
  color: #b0b0b0;
}

.flex-date-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.flex-date-between > * {
  color: #2194f3;
}