.chat-view-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
}

.chat-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-container > span {
  font-size: 18px;
  width: 250px;
  line-height: 1.3;
  text-align: center;
}

.chat-container > *:not(:last-child) {
  margin-bottom: 20px;
}

.chat-users-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 500px;
}

.chat-users-container > * {
  margin: 7px;
}

.default-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100px;
  width: 100px;
  border-radius: 20px;
  border: 2px dashed #858796;
}

.chat-user {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 20px;

  height: 100px;
  width: 100px;
}

.chat-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  margin-top: 5px;
  font-size: 12px;
}

.chat-user-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100px;
  width: 100px;
}

.chat-user-overlay i {
  visibility: hidden;
}

.chat-user-overlay:hover {
  background: rgba(33, 148, 243, 0.7);
  border-radius: 20px;
}

.chat-user-overlay:hover i {
  visibility: visible;
  color: #ffffff;
  font-size: 20px;
}

.chat-user > img {
  height: 100px;
  width: 100px;
  border-radius: 20px;
}

.chat-button-container {
  display: flex;
  flex-direction: row;
}

.chat-button-container > button {
  border-radius: 25px;
  width: 121px;
}

.chat-button-container > :first-child {
  margin-right: 5px;
}