.editor-container {
    min-height: 50px;
    max-height: 200px;
    padding: 10px;
    overflow-y: auto;
    overflow-x: auto;
    line-height: 0.9;
    color: black;
}

.DraftEditor-root {
    overflow-wrap: break-word; /* Ensure long words break */
    word-wrap: break-word; /* For better compatibility */
    white-space: pre-wrap; /* Maintain spaces and line breaks */
    word-break: break-word; /* Breaks words that are too long */
}