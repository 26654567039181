.body-style > :not(:last-child) {
  margin-bottom: 15px;
}

/* .details-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.details-container > * {
  width: 100%;
  max-width: 410px;
}

.patient-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.patient-details * {
  font-size: 14px;
}

.patient-details > :first-child,
.patient-details > :first-child i {
  color: #2194f3;
}

.patient-details > *:first-child {
  width: 100%;
  max-width: 150px;
}

.patient-details > *:last-child {
  width: 100%;
  max-width: 250px;
}

.patient-details * {
  color: #444444;
} */

.prescription-headers,
.cur-prescription-headers,
.del-prescription-headers {
  /* background: #ececec; */
  height: 40px;
}

.prescription-headers,
.cur-prescription-headers,
.del-prescription-headers,
.cur-prescription-item,
.del-prescription-item,
.prescription-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  border-bottom: 1px solid #d4d4d4;
  color: #444444;
}

.prescription-item,
.cur-prescription-item,
.del-prescription-item {
  border-top: 1px solid #d4d4d4;
}

.prescription-headers > *,
.prescription-item > *,
.cur-prescription-headers > *,
.cur-prescription-item > *,
.del-prescription-headers > *,
.del-prescription-item > * {
  padding: 0px 5px;
}

.prescription-headers > *,
.cur-prescription-headers > *,
.del-prescription-headers > * {
  font-weight: bold;
}

.prescription-headers > *:first-child,
.prescription-item > *:first-child,
.prescription-headers > *:nth-child(3),
.cur-prescription-headers > *:nth-child(3),
.del-prescription-headers > *:nth-child(3),
.prescription-item > *:nth-child(3),
.cur-prescription-item > *:nth-child(3),
.del-prescription-item > *:nth-child(3) {
  min-width: 150px !important;
  max-width: 150px !important;
}

.cur-prescription-headers > *:nth-child(4),
.cur-prescription-item > *:nth-child(4) {
  min-width: 100px !important;
  max-width: 100px !important;
}
.cur-prescription-headers > *:first-child,
.cur-prescription-item > *:first-child {
  min-width: 50px !important;
  max-width: 50px !important;
}

.prescription-headers > *:nth-child(3),
.cur-prescription-headers > *:nth-child(3),
.del-prescription-headers > *:nth-child(3),
.prescription-item > *:nth-child(3),
.cur-prescription-item > *:nth-child(3),
.del-prescription-item > *:nth-child(3) {
  text-align: center;
  min-width: 75px;
  max-width: 75px;
}

.prescription-headers > *:nth-child(2),
.prescription-item > *:nth-child(2),
.cur-prescription-headers > *:nth-child(2),
.cur-prescription-item > *:nth-child(2),
.del-prescription-headers > *:first-child,
.del-prescription-item > *:first-child {
  min-width: 450px;
  max-width: 450px;
}

.del-prescription-headers > *:nth-child(2),
.del-prescription-item > *:nth-child(2) {
  min-width: 75px;
  max-width: 75px;
}

.prescription-headers > *:last-child,
.prescription-item > *:last-child,
.cur-prescription-headers > *:last-child,
.cur-prescription-item > *:last-child,
.del-prescription-headers > *:last-child,
.del-prescription-item > *:last-child {
  min-width: 90px;
  max-width: 90px;
}

.prescription-headers > *:last-child,
.cur-prescription-headers > *:last-child,
.del-prescription-headers > *:last-child {
  background: #ffffff;
}

@media (max-width: 990px) {
  .prescription-headers > *:last-child,
  .prescription-item > *:last-child,
  .cur-prescription-headers > *:last-child,
  .cur-prescription-item > *:last-child,
  .del-prescription-headers > *:last-child,
  .del-prescription-item > *:last-child {
    min-width: 90px;
    max-width: 90px;
    position: sticky;
    right: 0;
    z-index: 1;
  }
}

.action-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.table-container {
  /* border: 1px solid #d4d4d4;
  box-shadow: 0px 0px 3px 0px #d4d4d4; */
}

.rx-list-container {
  overflow-x: scroll;
  position: relative;
}

.cm-list-container {
  overflow: scroll;
  height: 210px;
  position: relative;
  max-width: 650px;
  width: 100%;
  border: 1px solid #d4d4d4;
}

.prescription-headers *,
.cur-prescription-headers *,
.del-prescription-headers *,
.erx-item-container *,
.rx-input-container * {
  font-size: 12px;
}

.prescription-headers *,
.cur-prescription-headers * .del-prescription-headers * {
  text-transform: uppercase;
}

.erx-item-container > :not(:first-child) {
  border-top: none;
}

.prescription-item,
.cur-prescription-item,
.del-prescription-item {
  padding: 10px 10px;
}

.prescription-item:hover,
.cur-prescription-item:hover,
.del-prescription-item:hover,
.prescription-item:hover > *,
.cur-prescription-item:hover > *,
.del-prescription-item:hover > * {
  background: rgba(229, 229, 229, 0.8);
  cursor: pointer;
}

.default-rx-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid #d4d4d4;
}

.cp-height {
  min-height: calc(100vh - 405px);
}

.ap-height {
  min-height: calc(100vh - 440px);
}

.default-rx-view > span,
.default-cm-view > span {
  font-style: italic;
  color: #878787;
}

.filter-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.prescription-input,
.prescription-item > input[type="number"],
.duration-flex > input,
.duration-flex > select {
  height: 33px !important;
  border-radius: 5px !important;
}

.search-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  width: 100% !important;
}

.search-flex > * {
  margin-bottom: 15px;
}

.custom-search-flex {
  max-width: 200px;
  margin-bottom: 0px !important;
}

.icon-show {
  display: none;
}

.rbt {
  max-width: 470px;
}

.rbt,
.ind-rbt .rbt {
  width: 100%;
  margin-bottom: 0px;
}

.ind-rbt .rbt {
  max-width: 600px;
}
.modal-body input,
.modal-body textarea,
.modal-body select,
.rbt input {
  /* height: 33px; */
  font-size: 14px;
  border-radius: 5px;
}

.rbt-close {
  font-size: 14px !important;
}
.custom-search-flex select {
  height: 28px;
  padding: 4px 10px;
  font-size: 13px;
  border-radius: 5px !important;
}

.load-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 35px;
  padding: 0px 10px;
  width: 100%;
}

.load-more > span {
  font-size: 14px;
}

.duration-flex,
.direction-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.duration-flex > :first-child {
  max-width: 80px !important;
  margin-right: 10px;
}

.duration-flex > :last-child {
  flex-grow: 1;
  border-radius: 0px;
}

.direction-flex > * {
  flex-grow: 1;
  height: 33px;
  border-radius: 0px;
}

.rx-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.rx-input-container > *:not(:last-child) {
  margin-bottom: 10px;
}

.rx-input-container .rdw-editor-wrapper {
  max-width: 466px;
  width: 100%;
}

.rx-input-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rx-input-flex > :first-child {
  width: 125px;
  padding-right: 10px;
}

.rx-input-flex > :last-child {
  max-width: 300px;
  width: 100%;
  flex-grow: 1;
}

.instructions-container {
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
}

.instructions-flex {
  margin: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.instructions-flex > :first-child {
  margin-right: 5px;
  width: 35px;
  text-align: right;
}

.title-label {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: solid 1px #d4d4d4;

  padding: 5px 10px;
  background: #e5e5e5;
}

.pills-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.rdw-editor-wrapper {
  background: #eefff7;
}

.rte-flex {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: nowrap;
}

.count-cls {
  min-width: 15px;
}

@media (max-width: 614px) {
  .pills-input-container {
    flex-wrap: wrap;
  }

  .prescription-headers > *:nth-child(2),
  .prescription-item > *:nth-child(2),
  .cur-prescription-headers > *:nth-child(2),
  .cur-prescription-item > *:nth-child(2) {
    min-width: 250px;
    max-width: 250px;
  }
}

@media (max-width: 420px) {
  .span-remove {
    display: none;
  }

  .icon-show {
    display: unset;
  }

  .prescription-headers > *:last-child,
  .prescription-item > *:last-child {
    min-width: 45px;
    width: 45px;
  }

  .search-flex {
    flex-wrap: wrap !important;
  }

  .search-flex > * {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 992px) {
  .prescription-headers,
  .cur-prescription-headers,
  .del-prescription-headers,
  .cur-prescription-item,
  .erx-item-container,
  .prescription-item {
    width: max-content !important;
  }
}

.custom-content > iframe {
  height: calc(100vh - 62px);
  border: none;
}

.input-handler input {
  height: 33px;
  border-radius: 5px !important;
}

.ta-item {
  position: relative;
}

.ta-container {
  max-height: calc(100vh - 315px);
  overflow-y: auto;
}

.ta-container > *:not(:last-child) {
  margin-bottom: 15px;
}

.textarea-comp-container {
  width: 100%;
}

.doc-btn-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.doc-btn {
  background: #ffffff;
  border: solid 1px #e5e5e5;
  border-radius: 4px;
  height: 20px;
  min-width: 40px;
}

.doc-abs {
  position: absolute;
  top: 7px;
  right: 7px;
  border: solid 1px #e1847c;
  background: #e1847c;
  border-radius: 4px;
  height: 20px;
  color: #ffffff;
}

.textarea-display {
  white-space: pre-wrap;
  margin-bottom: 5px;
}

.textarea-display::first-line {
  font-weight: bold;
}
