.timeslot-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
}

.timeslot-item {
    display: flex;
    flex-direction: row;
    height: 30px;
    width: 100%;
    cursor: pointer;
}

.timeslot-item > *:first-child {
    display: flex;
    justify-content: center;
    width: 75px;
}

.timeslot-item > *:first-child span {
    font-size: 12px;
}

.timeslot-item > *:last-child {
    flex-grow: 1;
}

.timeslot-item > *:last-child:hover {
    background-color: #ced4da;
}

.timeslot-item:nth-child(even) > *:first-child {
    visibility: hidden;
}

.timeslot-item:nth-child(odd) > *:last-child {
    border-top: 1px solid #ced4da;
}

.timeslot-item-title {
    margin-left: 5px;
    font-size: 12px;
    color: #ffffff;
}

.disabled-background,
.disabled-background:hover {
    position: relative;
    background-color: #2194f3 !important;
    border-top: 1px solid #2194f3 !important;
}

.slot-divider {
    position: absolute;
    width: 100%;
    border-top: 1px solid #ced4da;
    top: -1px;
}