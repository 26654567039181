.CCM-Container {
    width: 0px;
    -webkit-transition: .5s; /* For Safari 3.1 to 6.0 */
    transition: .5s;
}

.CCM-Details {
    border-bottom: 1px solid rgba(0,0,0,0.12);
    min-width: 380px;
}

.document-list {
    position: absolute;
    top: 141px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 380px;
}