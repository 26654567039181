.Signup-Container {
    overflow: auto;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.confirm-phonenumber {
   color: rgb(74, 173, 82);
   font-weight: 500;
}

.confirm-phonenumber:active {
    color: rgb(122, 211, 129);
}

.disabled-confirm {
    color :rgba(0, 0, 0, 0.59) !important;
}

input:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}



/**
* Loading
*/

.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    margin: 0 auto;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 3px solid #2194f3;
    margin: 6px;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2194f3 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ringe div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}