.reminder-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  top: 0;
  left: 0;
}

.reminder-container {
    border-radius: 30px;
    display: grid;
    background: #ffffff;
    width: 450px;
    padding: 15px;
}

.selection-table {
    display: grid;
    grid-template-columns: 30% 70%;
  }

  .selection-table-filter {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 25% 60%;
    padding-left: 10px;
  }

.selection-table label {
    margin: 0px;
}

.date-picker {
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  text-align: center;
}