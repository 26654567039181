.diagnosis-container {
  width: 100%;
}

.diagnosis-flex-header,
.diagnosis-flex-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  width: inherit;
  min-height: 40px;
}

.diagnosis-flex-header {
  border-bottom: solid 1px #cccccc;
}

.diagnosis-flex-item {
  border-top: solid 1px #cccccc;
}

.diagnosis-flex-header > * {
  font-weight: bold;
}

.diagnosis-flex-header > *,
.diagnosis-flex-item > * {
  width: 100%;
  max-width: 150px;
  font-size: 12px;
}

.diagnosis-flex-header > *:nth-child(2),
.diagnosis-flex-item > *:nth-child(2) {
  width: 100%;
  max-width: 400px;
}

.patient-bypass .input-group-prepend {
  width: 200px;
}

.patient-bypass .input-group-prepend *,
.patient-bypass .center-vertical,
.patient-bypass .input-group * {
  font-size: 14px;
}

.patient-bypass .input-group input,
.patient-bypass .input-group button {
  height: 32px;
}

.form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.form-buttons > *:first-child {
  margin-right: auto;
}

.radio-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.radio-btn-container > *:first-child {
  width: 200px;
}

.radio-btn {
  display: flex;
  flex-direction: column;
  padding: 0.375rem 0.75rem;
}

.radio-btn label {
  margin-bottom: 0px;
}

.patient-form-container {
  padding-bottom: 60px;
}

.buttons-container {
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
}

.buttons-container button {
  margin: 5px;
}

.flexnowrap {
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
  align-items: center;
}

.flexnowrap > *:first-child {
  margin-right: 5px;
  margin-top: 3px;
}

@media (max-width: 420px) {
  .flexnowrap > *:first-child {
    margin-right: 0px;
  }
  .patient-form-container {
    padding-bottom: 120px;
  }
  .form-buttons > *:first-child {
    margin-right: 5px;
  }
  .form-buttons > *:nth-child(3) {
    margin-left: auto;
  }
  .buttons-container {
    height: 120px;
  }
}

.text-convo-header {
  text-align: center;
  font-size: 16px;
  padding-top: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.42);
}
