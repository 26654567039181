a.clickable {
  cursor: pointer;
}

.react-pdf__Page-canvas-container-2 {
  max-width: 100%;
}

.react-pdf__Page-canvas-container-2 canvas {
  width: 100% !important;
  height: 100% !important;
}

.bubble-style > span > div,
.bubble-replyTo  {
  padding-bottom: 5px;
}

.bubble-replyTo {
  margin-bottom: 5px;
}

.bubble-style > span > div > ul > li,
.bubble-style > span > div > ol > li,
.bubble-replyTo > div > ul > li,
.bubble-replyTo > div > ol > li {
  margin: 0px;
  line-height: 1.0 !important;
  margin-bottom: -1em;
  margin-left: 8px;
  /* margin-top: 0em; */
}

.bubble-style > span > div > ul,
.bubble-style > span > div > ol,
.bubble-replyTo > div > ul,
.bubble-replyTo > div > ol {
  padding-left: 20px;
  margin-top: -1em;
  /* margin-bottom: -1em; */
  line-height: 1.0 !important;
}

.bubble-style > span > div > p,
.bubble-replyTo > div > p {
  margin: 0px;
  line-height: 1.0 !important;
}

.replyTo-date {
  margin-top: 1rem;
  margin-bottom: 0px !important;
}

.limited-text,
.replyTo-limited-text {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.0 !important;
  display: block;
}

.limited-text {
  max-height: 26em;
}

.replyTo-limited-text {
  max-height: 15em;
}