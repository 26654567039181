
.Camera-Util-Container {
    position: relative;
}

.Camera-Video-Stream {
    max-width: 100%;
    border-radius: 5px;
}

.Photo-Captured {
    max-width: 100%;
    position: absolute;
    left: 0;
    bottom: 5px;
    top: 0;
}

.flash {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#fff;
    display: none
}