.calendar-view-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
}

.signin-flex {
  display: flex;
  flex-basis: row;
  justify-content: center;
  align-items: center;
}

.calendar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.big-calendar {
  height: 500px;
  width: 100%;
  max-width: 900px;
}

.event-button-container {
  max-width: 900px;
  width: 100%;
  margin: 10px 0px;
  text-align: left;
}

.event-button {
  border: solid 1px #ccc;
  background-color: #FFFFFF;
  color: #373A3C;
  font-size: 16px;
}

.event-button:hover {
  background-color: #e6e6e6;
}

.btn-active {
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  background-color: #e6e6e6 !important;
  border-color: #adadad;
}

.popover-body strong {
  color: #2095f3;
}

.popover-body .mb-xs {
  margin-bottom: 5px;
}

.rbc-event-content {
  display: flex !important;
  align-items: center;
  height: 15px !important;
}

.calendar-header {
  position: absolute;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
}

.calendar-header > * {
  margin-right: 10px;
}