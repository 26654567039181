.note-view {
  padding: 20px 0px;
  color: #444444;
  overflow-y: auto;
}

.patient-info-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 14px;
}

.patient-info-flex > * {
  width: 100%;
  max-width: 450px;
}

.patient-info-flex > * {
  margin-right: 20px;
}

.patient-info-item > * {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.patient-info-item > * > *:first-child {
  min-width: 100px;
  max-width: 125px;
  width: 100%;
  margin-right: 5px;
}

.pe-text {
  margin: 0px 5px 5px 5px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  white-space: pre-wrap;
}

.pe-text > div {
  margin-left: 15px;
  white-space: pre-wrap;
}

.cc-li-container > li {
  margin-right: 15px;
  font-size: 14px;
}