.conference-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 4;
}

.conference-main-container > button {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  border-radius: 35px;
  width: 185px;
  box-shadow: 0px 0px 6px 2px #c5c5c5;
}

.conference-main-container > button *:not(:last-child) {
  margin-right: 5px;
}

.conference-main-container > *:not(:last-child) {
  margin-bottom: 10px;
}

/* .hc-button-group-conference {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
}

.btn-telemed {
  border-radius: 20px;
  width: 140px;
  height: 140px;
} */

@media (max-width: 420px) {
  .react-datepicker-wrapper input {
    max-width: 100px;
  }
}

.signin-flex {
  display: flex;
  flex-basis: row;
  justify-content: center;
  align-items: center;
}

.event-form-flex > *:not(:last-child) {
  margin-bottom: 5px;
}

#manageEventModal .rbt-input-main {
  height: 21px !important;
  border: none !important;
}

/* .rbt-input-hint {
  border: none !important;
} */

.rbt-input {
  /* border-radius: 0px !important; */
}

/* .react-datepicker-wrapper input */
#manageEventModal
  input:not([class="rbt-input-main"]):not([class="rbt-input-hint"]) {
  border: 1px solid #ced4da !important;
  height: 38px !important;
}

#manageEventModal textarea {
  border: 1px solid #ced4da !important;
}

.picker-flex {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: nowrap;
}

.picker-flex > *:not(:last-child) {
  margin-right: 20px;
}

.picker-flex p {
  margin-bottom: 0px;
  font-size: 12px;
}

.timepicker-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.pad-side-sm {
  padding: 0 15px;
}

/* rc-time-picker */

.rc-time-picker-input {
  height: 38px;
  border-radius: 0px;
  width: 100%;
  font-size: 16px;
  max-width: 107px;
  color: #495057;
}

.rc-time-picker-panel-input {
  height: 38px;
  border-radius: 0px;
  width: 100%;
  font-size: 16px;
  color: #495057;
}

.rc-time-picker-panel-input-wrap {
  padding: 0px 6px;
}

.rc-time-picker-clear {
  top: 8px;
}

.rc-time-picker-clear-icon:after {
  font-size: 16px;
}

/* react-tags-autocomplete */

.react-tags {
  min-height: 38px;
  display: flex;
  flex-direction: column-reverse;
  border: solid 1px #ced4da;
}

.react-tags__search-input {
  min-width: 100%;
  height: 35px;
  padding: 0 5px;
}

.react-tags__selected {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: left;
  padding: 5px 5px 0px 5px;
}

.react-tags__selected > button {
  margin-bottom: 5px !important;
  text-align: left;
  height: 35px;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  float: right;
}

.react-tags__suggestions ul {
  margin-left: 5px;
  border: solid 1px #ced4da;
  box-shadow: 0px 0px 4px 0px #ced4da;
  position: absolute;
  background: white;
  padding: 5px;
}

.react-tags__suggestions li {
  list-style: none;
  /* height: 35px; */
  padding: 5px 0px;
}

.react-tags__suggestions li:hover {
  background: #2194f3;
  cursor: pointer;
  color: #ffffff;
}

.react-tags__suggestions li.is-active mark {
  background: none;
  font-weight: bold;
  color: #ffffff;
}

.react-tags__suggestions li mark {
  background: none;
  font-weight: bold;
}

.react-tags__suggestions li.is-active {
  background: #2194f3;
  color: #ffffff;
}

.guests-container {
  margin-bottom: 10px;
}

.guest-item > * {
  margin-right: 5px;
}

.guest-item-button {
  padding: 0px 5px;
  line-height: 15px;
  border-radius: 3px;
}

.rbt-parent .rbt-menu {
  max-height: 150px !important;
}

.rbc-off-range a {
  /* opacity: 0 !important; */
  display: none;
}

.tel-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.download-cls {
  width: 100px !important;
  background-color: black;
  padding: 0px;
}

.start-telemed-cls,
.schedule-telemed-cls {
  width: 51px !important;
  transition: width 0.5s !important;
}

.start-telemed-cls > span {
  min-width: 102px;
}

.schedule-telemed-cls > span {
  min-width: 134px;
}

.start-telemed-cls > span,
.schedule-telemed-cls > span {
  visibility: hidden;
  width: 0px !important;
}

.start-telemed-cls:hover,
.schedule-telemed-cls:hover {
  width: 185px !important;
}

.start-telemed-cls:hover > span,
.schedule-telemed-cls:hover > span {
  visibility: visible;
}

.schedule-telemed-cls > i {
  margin-left: 3px;
}

.schedule-telemed-cls:hover > i {
  margin-left: 0px;
}
