.Video-Container {
    overflow: auto;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: black;
}

.Inner-Container {
    /* max-width: 400px; */
}

.Video-Container .Instruction-Container {
    display: block;
    margin: 0 auto;
    width: 100%;
    /* height: 500px; */
    text-align: center;
    position: relative;
    top: 40%;
    transform: translateY(40%);
    color: white;
}
.Call-Container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Camera-Container {
    position: absolute;
    right: 6px;
    bottom: 0;
}
.Camera-Container video {
    height: 100px;
}

.Allow-Message {
    display: block;
    background: white;
    position: absolute;
    right: 2px;
    top: 10px;
    width: 305px;
    min-height: 70px;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
}


.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -7px;
    right: 7px;
}

.Call-Image-Profile {
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
    margin: 5px 5px 0 5px;
    display: inline;
}

.Other-Profile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
}

.Control-Container {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    z-index: 99999;
}

.Remote-Camera-Container {
    height: 100%;
}

.Remote-Camera-Container video {
    height: 100%;
    margin: 0 auto;
    display: block;
}

.Control-Container span {
    display: block;
    font-size: 12px;
}

.Status-Container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 120px;
    font-size: 20px;
    color: white;
}