.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 50px;
}

.blur {
    width: 0;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #02020266;
    z-index: 999;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 25px;
    margin-left: 50px;
}

.huchart-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.corp-setting {
    flex-direction: column;
    max-width:400px;
}

.corp-setting input,
.corp-setting textarea,
.corp-setting select {
    border-radius: 5px !important;
    border: none !important;
    background: #f5f5f5 !important;
}

.setting-container {
    /* position: absolute; */
    top: 50px;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.corp-setting {
    flex-direction: column;
    max-width:400px;
}

.corp-setting input,
.corp-setting textarea,
.corp-setting select {
    border-radius: 5px !important;
}

.setting-container-2 {
    padding: 20px 20px 20px 0px;
    background: rgb(248, 249, 250);
}

.setting-container h5 {
    font-size: 14px;
}

.setting-container .img-container,
.setting-container-2 .img-container,
.setting-container-2 .Profile-Setting-2 {
    padding-left: 90px;
    padding-right: 20px;
    position: relative;
    min-height: 80px;
    /* border-bottom: 1px solid rgba(0,0,0,0.12); */
}

.setting-container .img-container img,
.setting-container-2 .img-container img {
    height: 60px;
    width: 60px;
    border-radius: 15px !important;
    position: absolute;
    left: 20px;
    top: 0;
}

.setting-container .img-container p,
.setting-container-2 .img-container p,
.setting-container-2 .Profile-Setting-2 p {
    margin: 0;
    white-space: nowrap;
    /* padding-right: 40px; */
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 100%;
    margin: 0;
    padding-top: 10px;
    padding-right: 20px;
}

.setting-container .img-container span,
.setting-container-2 .img-container span {
    font-size: 12px;
    cursor: pointer;
    display: block;
    font-weight: 500;
}

.setting-container .img-container span:hover {
    color: #2194f3a6 !important;
}

.setting-container .detail-container,
.setting-container-2 .detail-container-2 {
    position: relative;
    padding: 10px 20px;
}

.setting-container .detail-padding,
.setting-container-2 .detail-padding {
    position: relative;
    padding: 5px;
}

.setting-container .detail-container p,
.setting-container-2 .detail-container-2 p {
    font-size: 15px;
    margin: 0;
}

.setting-container .detail-container .action,
.setting-container-2 .detail-container-2 .action {
    font-size: 17px;
    color: #000000de;
    cursor: pointer;
    /* margin: 10px 0px; */
    white-space: nowrap;
}

.detail-container.active {
    background: #cce5ff;
}

.detail-container.active .action {
    color: #2193f3;
}

.detail-container-2.active {
    background: #cce5ff;
}

/* .detail-container-2.active .action {
    color: #2193f3;
} */

.container-padding-md {
    padding: 15px;
}

.detail-container-2:hover {
    background-color: #f2f7ff !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.setting-container .detail-container .action:focus,
.setting-container-2 .detail-container-2 .action:focus {
    color: #2194f391;
}

.shadow-2 {
    box-shadow: 0px 0px 8px 4px #e5e5e5 !important;
}

.menu-btn {
    font-size: 14px;
    color: #000000c4;
    margin-left: auto;
}

.info-btn {
    display: none;
}


.list-group-item:hover > .menu-btn {
    display: block;
}

.list-group-item:hover > .info-btn {
    display: block;
}

.dropdown-item {
    font-size: 13px;
}


/* Tooltip container */
/* .custom-tooltip {
    display: inline-block;
} */

/* Tooltip text */
.custom-tooltip .custom-tooltiptext {
    visibility: hidden;

    max-width: 200px;
    min-width: 150px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem;
    font-size: 12px;


    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 145%;
    right: -8px;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 1;
    transition: opacity 0.3s;

    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
}

/* Tooltip arrow */
.custom-tooltip .custom-tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 9px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .custom-tooltiptext {
    visibility: visible;
    opacity: 1;
}


/* CHIPS */

.chips {
    width: 60px;
    height: 60px;
    text-align: center;
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 5px;
    margin: 0 5px;
    border-radius: 0.5rem;
}

.chips.active {
    background-color: #f6c23e;
    color: white;
}

.chips.active-urgent {
    background-color: #e74a3b;
    color: white;
}

.chips .chip-img {
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 13px;
    margin: 0 auto;
}

.chips span {
    font-size: 14px;
    white-space: nowrap;
}

.chip-svg{
    position: absolute;
    top: 0px;
    color: gray;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 15px;
    height: 15px;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
}

.chip-svg:hover{
    color: #666666;
}

.close-chip {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #504e4e;
    border-radius: 50%;
    color: white;
    cursor: pointer;
}

.close-chip:hover {
    background: gray;
}


[data-toggle="collapse"] i:before{
    content: "\f077";
}

[data-toggle="collapse"].collapsed i:before{
    content: "\f078";
}


.nav-tabs {
    border: 0;
}

.nav-tabs .nav-item {
    width: 50%;
}

.nav-tabs .nav-link {
    border: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: 0;
    color : #2194f3;
    border-bottom: 2px solid #2194f3;
}





.Form-Container {
    max-width: 400px;
    margin: 40px auto;
    border-radius: 4px;
    /*margin-top: 100px;*/
}

.Form-Container .Error-Container {
    background: #D84B55;
    /* background: #f44336; */
    color: white;
    height: 40px;
    text-align: center;
    padding-left: 0px !important;
    padding-top: 7px;
}

.Form-Container .Verify-Container {
    background: #F2BC52;
    color: white;
    height: 40px;
    text-align: center;
    padding-left: 0px !important;
    padding-top: 7px;
}

.Form-Container .Logo {
    width: 80px;
    height: 70px;
    margin: 0 auto;
    display: block;
}

.Form-Container h1 {
    letter-spacing: 3px;
    text-align: center;
}

.card {
    padding-left: 35px;
    position: relative;
    width: 100%;
    border: 0;
    border-radius: 0px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card input {
    outline: none;
    -webkit-appearance: none;
    border: 0;
    height: 40px;
}

.card textarea {
    outline: none;
    -webkit-appearance: none;
    border: 0;
    resize: none;
}

/* For items */
.border-item {
    /* border: 1px solid #ececec !important; */
    box-shadow: 0px 4px 7px -4px #888888;
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 10px;
}

.layout-item {
    padding: 10px;
    /* display: flex;
    flex-direction: row;
    align-items: center; */
    display: grid;
    grid-template-columns: auto 2fr auto;
    align-items: center;
}

.border-item.active {
    background-color: #e2e8ee !important;
}

.task-priority-cls {
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
}

.priority-flex {
    width: 110px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}