.react-datepicker-popper {
    background-color: white;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/* Style for the popup calendar */
.react-datepicker {
    border: none;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Style for the header */
  .react-datepicker__header {
    background: white;
    border-bottom: none;
  }

  .react-datepicker__header button {
    color: #ccc;
    background-color: white;
  }

  .react-datepicker__header select {
    font-size: 13.5px;
  }
  
  /* Bold weekday labels */
  .react-datepicker__day-name {
    color: #ccc !important;
    font-size: 10.5px !important; /* Adjust size as needed */
    text-transform: uppercase; /* Ensure consistency */
    margin: 0.05rem 4px;
  }
  
  /* Selected date styling */
  .react-datepicker__day--selected {
    background-color: #1976d2 !important;
    color: white !important;
    border-radius: 50%;
  }
  
  /* Hover effect on dates */
  .react-datepicker__day:hover {
    background-color: #e3f2fd !important;
    border-radius: 50%;
  }
  
  .react-datepicker__day {
    width: 25px !important;  /* Set a fixed width */
    height: 25px !important; /* Set a fixed height */
    line-height: 35px !important; /* Align text vertically */
    text-align: center; /* Align text horizontally */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 2px 5px; /* Add slight spacing between squares */
    color: #000;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #1976d2 !important;
    color: white !important;
    border-radius: 50%;
  }

  .react-datepicker__input-container input {
    font-size: 14px; /* Adjust as needed */
    cursor: auto;
  }

  .fa-times {
    font-weight: lighter;
  }

  /* .react-datepicker-popper {
    transform: translate(600px, 100px) !important; Moves left 15px and down 20px
  } */