// Global component styles

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
  // background-color: $gray-100;
}

a {
  &:focus {
    outline: none;
  }
}

// Main page wrapper
#wrapper {
  display: flex;
  #content-wrapper {
    width: 100%;
    overflow-x: hidden;
    #content {
      flex: 1 0 auto;
    }
  }
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: $white;
  background: fade-out($gray-800, 0.5);
  line-height: 46px;
  &:focus,
  &:hover {
    color: white;
  }
  &:hover {
    background: $gray-800;
  }
  i {
    font-weight: 800;
  }
}

// Navbar styles
.hc-nav {
  border-bottom: 1px solid #2194f3;
  padding: unset;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.hc-nav-link-container {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.hc-nav-link-container a {
  color: #2194f3;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 10px;
}

.hc-nav-link-container a:hover {
  background-color: #f0f2f5;
}

.hc-nav-link-container a.active {
  color: #fff;
  background: #2194f3;
}

// Profile thumbnail
.Profile-Setting {
  height: 50px;
  width: 50px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.Profile-Setting {
  position: relative;
}

.Profile-Setting img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 5px;
  border: 2px solid #908e8e;
  border-radius: 15px !important;
  position: absolute;
  // left: 20px;
  top: 0;
}

.Profile-Setting:hover img {
  border: 2px solid #2194f3;
}

.Profile-Setting:active img {
  border: 2px solid #2194f39e;
}

// .Profile-Setting-2 p {
//   color: black;
//   display: inline;
// }

//
div .icon-wrapper {
  position: absolute;
  opacity: 0.5;
  right: 5px;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.line-clamp-8 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* number of lines to show */
  line-clamp: 8;
  -webkit-box-orient: vertical;
}

.line-clamp-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.img-centered {
  display: inline-block;
  object-fit: cover;
  width: 100%;
}

.d-flex-text {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
