.Profile-Setting-Nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 50px;
}

.blur {
    width: 0;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #02020266;
    z-index: 9999;
}

.Profile-Setting-Nav a {
    /* padding: 8px 8px 8px 32px; */
    padding: 8px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.Profile-Setting-Nav a:hover {
    color: #f1f1f1;
}

.Profile-Setting-Nav .closebtn {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 25px;
    margin-left: 50px;
}


.Account-Setting-Container {
    position: absolute;
    left: 350px;
    bottom: 0;
    top: 50px;
    right: 0;
    padding: 20px;
    overflow: auto;
}

.Form-Container {
    max-width: 400px;
    margin: 40px auto;
    border-radius: 4px;
    /*margin-top: 100px;*/
}

.Form-Container .Error-Container {
    background: #D84B55;
    /* background: #f44336; */
    color: white;
    height: 40px;
    text-align: center;
    padding-left: 0px !important;
    padding-top: 7px;
}

.Form-Container .Verify-Container {
    background: #F2BC52;
    color: white;
    height: 40px;
    text-align: center;
    padding-left: 0px !important;
    padding-top: 7px;
}

.Form-Container .Logo {
    width: 80px;
    height: 70px;
    margin: 0 auto;
    display: block;
}

.Form-Container h1 {
    letter-spacing: 3px;
    text-align: center;
}

.Form-Container .card {
    padding-left: 35px;
    position: relative;
    width: 100%;
    border: 0;
    border-radius: 0px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.Form-Container .card:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.Form-Container .card input {
    outline: none;
    -webkit-appearance: none;
    border: 0;
    height: 40px;
}

.Form-Container .card select {
    outline: none;
    border: 0;
    height: 40px;
}

.Form-Container .card i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}



.file-upload {
    display: none;
}


.Upload-Button-Container {
  position: absolute;
  top: 12px;
  left: 45px;
  font-size: 25px;
  color: #666666;
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}
.Upload-Button-Container:hover {
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}
.upload-button {
    font-size: 24px;
    color: white;
    background: #2194f3;
    border-radius: 50%;
    padding: 5px;
    border: 3px solid lightgray;
    cursor: pointer;
    position: absolute;
    top: 75px;
    left: 40px;
}

.upload-button:hover {
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  color: #2194f3;
  background: white;
}

.Update-Detail-Button {
    font-size: .9rem;
    position: absolute;
    right: 25px;
    top: -30px;
    color: rgb(74, 173, 82);
    cursor: pointer;
}

.Update-Detail-Button:hover {
    color: rgba(74, 173, 82, 0.71);
}

hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.Confirm-Button {
    color: rgb(74, 173, 82);
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
}

.Confirm-Button:hover {
    color: rgba(74, 173, 82, 0.71);
}

.Cancel-Button {
    color: black;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    background: transparent;
}

.Cancel-Button:hover {
    color: #0000008a;
}

.Vertical-Line-Seperator {
    height: 30px;
    border-left: 1px solid #00000061;
}

.Corporate-Name {
    font-size: 17px;
    padding-top: 12px;
    margin-bottom: 0;
    padding: 0;
}

.Corporate-Owner {

}

.Corporate-Content {
    margin-top: 30px;
}

.Check-Container {
    width: 50px;
    height: 40px;
    display: inline;
    /* text-align: right; */
    margin: 0;
    position: absolute;
    top: 15px;
    right: 0;
    text-align: center;
}

.Patient-Star {
    color: #00000045;
    font-size: 17px;
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 99999;
    cursor: pointer;
}

.Star-Yellow {
    color: rgb(242, 188, 82) !important;
}