/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/templates/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

#wrapper {
  /* border: 5px dotted black; */
  overflow-x: hidden;
  height: 100vh;
  position: relative;
}

#sidebar-wrapper {
  border-left: 1px solid #dadbdc;
  /* margin-right: -15rem; */
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  /* border: 10px solid grey; */
  position: relative;
  min-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#wrapper.toggled #sidebar-wrapper {
  margin-right: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-right: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-right: -30rem;
  }
}

.Document-Panel {
  height: 100%;
  overflow-y: auto;
  padding: 5px;
}

.doc-category:hover {
  background-color: #d3d3d3;
}
