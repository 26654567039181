.details-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.details-container > * {
  width: 100%;
  max-width: 410px;
}

.patient-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.patient-details * {
  font-size: 14px;
}

.patient-details > :first-child,
.patient-details > :first-child i {
  color: #2194f3;
}

.patient-details > *:first-child {
  width: 100%;
  max-width: 150px;
}

.patient-details > *:last-child {
  width: 100%;
  max-width: 500px;
}

.patient-details * {
  color: #444444;
}

.patient-image {
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
  width: fit-content;
  margin-left: auto;
}

.setting-modal-headers {
  /* background: #ececec; */
  height: 50px;
  border-bottom: 1px solid #d4d4d4;
}

.setting-modal-headers,
.setting-modal-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0px 10px;
  color: #000000;
}

@media (max-width: 990px) {
  .setting-modal-headers > *:last-child,
  .setting-modal-item > *:last-child {
    position: sticky;
    background: #ffffff;
    right: 0;
    z-index: 1;
  }
}

.setting-modal-headers {
  align-items: center;
}

.setting-modal-headers > * {
  font-weight: bold;
  height: 95%;
  display: flex;
  align-items: center;
}

.setting-modal-headers > *,
.setting-modal-item > * {
  font-size: 14px;
  max-width: 200px;
  width: 100%;
}

.setting-modal-headers > *:not(:last-child),
.setting-modal-item > *:not(:last-child) {
  margin-right: 5px;
}

.diagnosis .setting-modal-headers > *:first-child,
.diagnosis .setting-modal-item > *:first-child {
  font-size: 14px;
  min-width: 100px;
  max-width: 100px;
}

.problems .setting-modal-headers > *:first-child,
.problems .setting-modal-item > *:first-child {
  font-size: 14px;
  min-width: 450px;
  max-width: 450px;
  width: 100%;
}

.grouped-list .setting-modal-headers > *:first-child,
.grouped-list .setting-modal-item > *:first-child {
  font-size: 14px;
  min-width: 500px;
  max-width: 500px;
  width: 100%;
}

.diagnosis .setting-modal-headers > *:nth-child(2),
.diagnosis .setting-modal-item > *:nth-child(2) {
  font-size: 14px;
  max-width: 450px;
  min-width: 450px;
  width: 100%;
}

.orders .setting-modal-headers > *:nth-child(2),
.orders .setting-modal-item > *:nth-child(2) {
  font-size: 14px;
  max-width: 500px;
  min-width: 500px;
  width: 100%;
}

.orders .setting-modal-headers > *:nth-child(3),
.orders .setting-modal-item > *:nth-child(3) {
  font-size: 14px;
  max-width: 150px;
  min-width: 150px;
  width: 100%;
}

.problems .setting-modal-headers > *:nth-child(2),
.problems .setting-modal-item > *:nth-child(2),
.diagnosis .setting-modal-headers > *:nth-child(3),
.diagnosis .setting-modal-item > *:nth-child(3),
.grouped-list .setting-modal-headers > *:nth-child(2),
.grouped-list .setting-modal-item > *:nth-child(2) {
  min-width: 200px;
  max-width: 200px;
  width: 100%;
}
.grouped-list .setting-modal-headers > *:last-child,
.grouped-list .setting-modal-item > *:last-child {
  min-width: 80px;
  max-width: 80px;
  text-align: right;
  width: 100%;
}

.orders .setting-modal-headers > *:first-child,
.orders .setting-modal-item > *:first-child {
  min-width: 30px;
  max-width: 30px;
  text-align: center;
  width: 100%;
}
.orders .setting-modal-headers > *:nth-child(4),
.orders .setting-modal-item > *:nth-child(4) {
  min-width: 120px;
  max-width: 120px;
  text-align: center;
  width: 100%;
}

.setting-modal-headers > *:last-child,
.setting-modal-item > *:last-child {
  min-width: 60px;
  width: 60px;
  text-align: center;
}

.setting-modal-headers > *:last-child {
  display: flex;
  justify-content: center;
}

.diagnosis .setting-modal-headers > *:nth-child(4),
.diagnosis .setting-modal-item > *:nth-child(4) {
  /* .diagnosis .setting-modal-headers > *:nth-child(5),
.diagnosis .setting-modal-item > *:nth-child(5) { */
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}

.problems .setting-modal-headers > *:nth-child(3),
.problems .setting-modal-item > *:nth-child(3),
.problems .setting-modal-headers > *:nth-child(4),
.problems .setting-modal-item > *:nth-child(4) {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}

.setting-modal-item-container > :not(:first-child) {
  border-top: none;
}

.setting-modal-item {
  padding: 10px 10px;
}

.setting-modal-item:hover,
.setting-modal-item:hover > *:last-child {
  /* background: #eaeaea; */
  cursor: pointer;
}

.default-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: calc(100% - 145px);
  border: 1px solid #d4d4d4;
}

.default-view > span {
  font-style: italic;
  color: #878787;
}

.filter-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.date-picker-custom,
.setting-modal-input {
  height: 33px !important;
  border: 1px solid #e4e4e4;
}

.setting-modal-input-2 {
  border: 1px solid #e4e4e4;
  height: 33px;
}

.search-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  width: 100% !important;
}

.search-flex > * {
  margin-bottom: 15px;
}

.custom-search {
  max-width: 450px;
}

.icon-show {
  display: none;
}

.search-identifier .rbt,
.search-identifier .rbt-container {
  max-width: 600px !important;
  width: 100%;
  margin-bottom: 0px;
}

.rbt-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 10px;
}

.load-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 35px;
  padding: 0px 10px;
  width: 100%;
}

.load-more > span {
  font-size: 14px;
}

.black {
  color: #000000;
}

#card-item-list > div::first-line {
  font-weight: bold;
}

.active-action-item {
  background: #ffffff;
  animation: initBackground 2s;
}

@keyframes initBackground {
  from {
    background-color: #2194f3;
  }
  to {
    background-color: #ffffff;
  }
}

@media (max-width: 420px) {
  .span-remove {
    display: none;
  }

  .date-picker-custom {
    width: 200px;
  }

  .search-flex {
    flex-wrap: wrap !important;
  }

  .search-flex > * {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 614px) {
  .diagnosis .setting-modal-headers > *:nth-child(2),
  .diagnosis .setting-modal-item > *:nth-child(2) {
    font-size: 14px;
    max-width: 250px;
    min-width: 250px;
    width: 100%;
  }
}

@media (max-width: 919px) {
  .diagnosis .setting-modal-headers,
  .diagnosis .setting-modal-item {
    width: max-content;
  }
}

@media (max-width: 990px) {
  .problems .setting-modal-headers,
  .problems .setting-modal-item,
  .orders .setting-modal-headers,
  .orders .setting-modal-item,
  .grouped-list .setting-modal-headers,
  .grouped-list .setting-modal-item {
    width: max-content;
  }
}
