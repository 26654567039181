.discussion-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  top: 0;
  left: 0;
}

.discussion-container {
  border-radius: 30px;
  display: grid;
  background: #ffffff;
  width: 450px;
  padding: 15px;
  height: 750px;
}

.discussion-comment {
  height: 330px;
  overflow-y: scroll;
}

.comment-bubble {
  background-color: lightblue;
  border-radius: 15px;
  font-size: 14px;
  /* line-height: 1.34; */
}

.comment-text {
  font-size: 14px;
  line-height: 1.34;
}

.comment-sender {
  font-size: 11px;
  margin: 3px 3px 0 8px;
  padding-left: 30px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  line-height: 12px;
}

.task-details {
  font-size: 14px;
  display: grid;
  grid-template-rows: repeat(4, 20px);
  grid-template-columns: 100px 130px;
}

.task-details > :first-child,
.task-details > :nth-child(3),
.task-details > :nth-child(5),
.task-details > :nth-child(7) {
  color: #2194f3;
  /* font-weight: 500; */
}

.none {
  font-style: italic;
  color: grey;
}