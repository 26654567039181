.private-call-container {
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    background-color: black;
}

.caller-video-item {
    position: absolute;
    height: 100px;
    top: 10px;
    right: 10px;
    width: 200px;
}

.receiver-video-item {
        
}

.receiver-name {
    color: white;
    font-size: 24px;
}

.call-status {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 67%;
    text-align: center;
    color: white;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.controls-container-private {
    position: absolute;
    z-index: 4;
    width: 100%;
    bottom: 0;
    background: #908e8e4a;
    padding: 5px;
}

.caller-video-item .camera-placeholder {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6c757d;
}

.profile-image.large {
    width: 100px;
    height: 100px;
}

.no-answer-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
}
