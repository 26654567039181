
.Tab-Header {
    padding: 0;
    margin: 0;
    list-style: none;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    flex-flow: nowrap;
    -webkit-flex-flow: nowrap;
    justify-content: space-around;
    /* max-height: 50px;
    min-height: 50px; */
}

.Tab-Header .Logo {
    height: 40px;
    width: 40px;
}


.Tab-Icons {
    position: relative;
    /* padding: 10px; */
    line-height: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
    /* max-width: 40px;
    min-width: 40px;
    max-height: 40px; */
    width: 100%;
    cursor: pointer;
    background: white;
}

.Tab-Icons img {
    width: 25px;
}

.Tab-Search-Container {
    position: relative;
    width: 100%;
}

.Tab-Search-Container input {
    padding: 3px 30px 3px 35px;
    /* height: 40px; */
    color: black;
    font-size: 15px;
    background: white;
    border: 1px solid #ececec;
    border-radius: 9px;
    margin: 0px;
    width: 100% !important;
    outline: none;
}

.Tab-Search-Container i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color : #908e8e;
}

.Tab-Container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    /* border: 5px solid cyan; */
}

/* ITEM STYLES */

.list-group-item .profile-image {
    position: absolute;
    width: 50px;
    height: 50px;
    overflow: hidden;
    left: 5px;
    top: 5px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 15px !important;
}

.list-group-item .name {
    color: rgb(0, 0, 0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    width: 100%;
    margin: 0px;
}

.list-group-item .secondary-content {
    position: absolute;
    top: 16px;
    right: 5px;
    color: #26a69a;
}

.list-group-item .text-content,
.list-search-item .text-content {
    text-overflow: ellipsis;
    padding-right: 40px;
    white-space: nowrap;
    overflow: hidden;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    font-weight: 300;
}

.plane-btn {
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #0000009c;
    display: block;
}

.loading-btn {
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color :#2194f3;
    display: block;
}

.unSeenMessage {
    font-weight: 500 !important;
    color: black !important;
}

.hc-badge-message {
    position: absolute;
    left: 45px;
    top: 5px;
    font-size: 9px;
    background: #2194f3;
    color: white;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-border-radius: 5;
    font-weight: bold;
    line-height: 2.3em;
    margin-right: 5px;
    text-align: center;
    width: 2.3em;
}

.hc-icon-badge {
    position: absolute;
    right: 7px;
    top: -7px;
    font-size: 9px;
    background: red;
    color: white;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-border-radius: 5;
    font-weight: bold;
    line-height: 2.3em;
    margin-right: 5px;
    text-align: center;
    width: 2.3em;
}

@media (max-width: 420px) {
    .hc-icon-badge {
        right: -12px;
    }
}

.activeThread {
    background: rgba(85, 128, 162, 0.14) !important;
}


.Profile-Setting {
    height: 50px;
    width: 50px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.Profile-Setting img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 5px;
    border:  2px solid #908e8e;
    position: relative;
}

.Profile-Setting:hover img {
    border: 2px solid #2194f3;
}

.Profile-Setting:active img {
    border: 2px solid #2194f39e;
}

.Create-Button {
    position: absolute;
    right: 0;
    font-size: 20px;
    height: 49px;
    width: 49px;
    cursor: pointer;
    color: #908e8e;
}

.Create-Button:active {
    color: #2194f3;
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__input-hub {
    width: 100%;
}

.datepicker {
    min-width: 18rem;
}

.datepicker table {
    width: 100%;
}

.Menu-Icon-Container {
    position: absolute;
    bottom: 2px;
    right: 1px;
}

.Menu-Icon-Container i {
    font-size: 10px;
    color: #2194f3;
    background: white;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid lightgray;
    cursor: pointer;
}

.Profile-Setting:hover .Menu-Icon-Container i {
    background: #2194f3;
    color: white;
}

.Tab-Row {
    /* margin-top: 5px;
    margin-bottom: 5px; */
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
}

.hc-activity-badge {
    position: absolute;
    left: 45px;
    top: 5px;
    font-size: 9px;
    background: #2194f3;
    color: white;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -webkit-border-radius: 5;
    font-weight: bold;
    line-height: 2.3em;
    margin-right: 5px;
    text-align: center;
    width: 2.3em;
}

.unreadCls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
}

.unreadCls > span, .unreadCls > a {
    font-size: 12px;
    font-weight: bold;
}

.profile-image-sm {
    position: absolute;
    width: 25px;
    height: 25px;
    overflow: hidden;
    left: 5px;
    top: 5px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 15px !important;
}

.list-search-item {
    position: relative;
    display: block;
    padding: 10px;
    background-color: #ffff;
}

.SideTab-messages-results {
    overflow: hidden;
    text-overflow: ellipsis;
    /* overflow-y: auto; */
    height: 100%;
}

::-webkit-scrollbar-thumb {
    height: 20px;
}