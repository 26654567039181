.error-template{
    padding: 40px 15px;
    text-align: center;
}
.error-actions
{
    margin-top: 15px;
    margin-bottom: 15px;
}
.error-actions .btn
{
    margin-right: 10px;
}
.message-box h1
{
    color: #252932;
    font-size: 98px;
    font-weight: 700;
    line-height: 98px;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
}