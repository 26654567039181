

.dueDatePicker {
    width: 110px;
}

.dueDatePicker > div.react-datepicker-wrapper, 
.dueDatePicker > div > div.react-datepicker__input-container,
.dueDatePicker > div > div.react-datepicker__input-container input {
   width: 100px !important;
}

.action-text > div > ul > li,
.action-text > div > ol > li,
.view-details > div > ul > li,
.view-details > div > ol > li {
  margin: 0px;
  line-height: 1.0 !important;
  margin-bottom: -1em;
}

.action-text > div > ul,
.action-text > div > ol,
.view-details > div > ul,
.view-details > div > ol {
  padding-left: 10px;
  margin-top: -1em;
  margin-bottom: -1em;
}

.action-text > div > p,
.view-details > div > p {
  margin-bottom: -1.5em;
  margin-top: 0em;
}

.view-details > div > ul > li,
.view-details > div > ol > li {
  margin-left: 5px;
}

.view-details {
    border: 1px solid #d1d3e2;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    margin: 0px;
    height: 300px;
    white-space: pre-line;
    overflow: hidden;
}