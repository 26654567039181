
.pulsating-circle {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translateX(-50%) translateY(-50%);
    width: 50px;
    height: 50px;
  }
  .pulsating-circle:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 50%;
      background-color: #01a4e9;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
  }

  .audio-record-btn:hover, .audio-record-btn:visited, .audio-record-btn:link, .audio-record-btn:active
  {
      text-decoration: none;
  }

  .audio-record-btn span{
      font-size: 12px;
      display: block;
      color: #00000082;
  }

  .audio-record-btn:hover span {
      color: #2194f3;
  }

  .audio-btn-disabled {
      color: #00000082 !important;
  }

  .audio-btn-disabled:hover span{
      color: #00000082 !important;
  }
