.progress-notes-container {
  color: #444444;
  overflow-y: auto;
}

.mb-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.pn-title-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.pn-title-flex > :last-child {
  margin-left: auto;
}

.pn-patient-details-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.pn-patient-details-flex > div {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin: 5px;
}

.pn-patient-details-flex > div span:last-child {
  font-size: 12px;
}

.pn-patient-details-flex > div span:first-child {
  color: #2194f3;
}

.pn-patient-details-flex > img {
  height: 50px;
  width: 50px;
  margin: 5px;
  border-radius: 5px;
}

.pn-title {
  font-size: 24px;
  /* margin-left: 10px; */
  color: #2194f3;
}

.document-titles {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-size: 14px;
}

.document-titles span {
  margin-left: 10px;
}

.pn-template-container {
  background: #f0f3f4;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.pb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.template-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 270px;
}

.template-flex > div {
  width: 170px;
}

.template-container:hover > .template-item {
  border: solid 1px #2194f3;
  color: #2194f3;
  cursor: pointer;
}

.template-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 175px;
  width: 150px;
  background: #ffffff;
  border: solid 1px #e5e5e5;
  border-radius: 15px;
  margin: 10px;
  overflow: hidden;
}

.template-item img {
  height: 90%;
}

.template-item-text {
  display: flex;
  flex-direction: column;
  height: 40px;
  font-size: 12px;
  margin-left: 10px;
}

.template-item-text > :first-child {
  font-weight: bold;
}

.template-item-text > :nth-child(2) {
  font-size: 10px;
  color: #888888 !important;
}

.progress-table-container {
  margin: 0px 10px;
  min-height: 250px;
  overflow-x: auto;
}

.progress-table-empty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  height: calc(100vh - 423px);
}

.progress-table-empty span {
  color: #858585;
}

.progress-list-header,
.progress-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 50px;
}

.progress-list-header {
  color: #2194f3;
  font-size: 14px;
}

.progress-list-item {
  font-size: 12px;
  border-bottom: 1px solid #e5e5e5;
  width: max-content;
}

.progress-list-header div {
  font-weight: bold;
}

.progress-list-header > :not(:last-child),
.progress-list-item > :not(:last-child) {
  flex-grow: 1;
  min-width: 150px;
  max-width: 150px;
}

.progress-list-header > * {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 50px;
}

.progress-list-item > * {
  display: flex;
  align-items: center;
  padding: 5px;
  min-height: 50px;
}

.progress-list-header > :last-child,
.progress-list-item > :last-child {
  width: 50px;
  min-width: 50px;
  max-width: 100%;
  text-align: center;
}

.progress-list-item:hover,
.progress-list-item:hover > div {
  background: #2194f3;
}

.progress-list-item:hover,
.progress-list-item:hover i {
  color: #ffffff;
}

.pn-con .modal-full-height {
  box-shadow: 0px 0px 8px 0px #858585;
}

.drpdwn-pn .dropdown-menu.show {
  left: -3px !important;
}

.orders-modal-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.orders-modal-flex > *:not(:last-child) {
  margin-right: 10px;
}

/* .orders-modal-flex > *:last-child {
  margin-left: auto;
} */

@media (max-width: 420px) {
  .orders-modal-flex > * {
    margin-bottom: 10px;
  }
}

@media (max-width: 990px) {
  .progress-list-header > * {
    border-bottom: 2px solid #e5e5e5;
  }

  .progress-list-header > *:last-child,
  .progress-list-item > *:last-child {
    position: sticky;
    right: 0;
    background: #ffffff;
    z-index: 1;
  }
}

@media (min-width: 991px) {
  .progress-list-header {
    border-bottom: 2px solid #e5e5e5;
  }

  .progress-list-item {
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
  }
}

.medicationButtons {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 70px;
  background: #ffffff;
  border-top: solid 1px #e5e5e5;
  z-index: 1;
}

.medicationButtonsFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 0px;
}

.componentContainer {
  background: #e5e5e5;
  overflow-y: auto;
  min-height: 100vh;
}

.componentInnerContainer {
  background: #ffffff;
  padding-bottom: 90px;
  min-height: 100vh;
}

@media (max-width: 420px) {
  .medicationButtons {
    height: 120px;
  }

  .medicationButtonsFlex {
    padding: 15px 0px;
    justify-content: center;
    align-items: center;
  }

  .medicationButtonsFlex > button {
    margin-bottom: 10px;
  }

  .componentInnerContainer {
    padding-bottom: 140px;
  }
}
