.Login-Container {
  overflow: auto;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.Form-Container {
  max-width: 400px;
  margin: 40px auto;
  border-radius: 4px;
  /*margin-top: 100px;*/
}

.Form-Container .Error-Container {
  background: #d84b55;
  /* background: #f44336; */
  color: white;
  height: 40px;
  text-align: center;
  padding-left: 0px !important;
  padding-top: 7px;
  padding-bottom: 7px;
}

.Form-Container .Verify-Container {
  background: #f2bc52;
  color: white;
  height: 40px;
  text-align: center;
  padding-left: 0px !important;
  padding-top: 7px;
  padding-bottom: 7px;
}

.Form-Container .Logo {
  width: 80px;
  height: 70px;
  margin: 0 auto;
  display: block;
}

.Form-Container h1 {
  letter-spacing: 3px;
  text-align: center;
}

.Form-Container .card {
  padding-left: 35px;
  position: relative;
  width: 100%;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Form-Container .card:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Form-Container .card input {
  outline: none;
  -webkit-appearance: none;
  border: 0;
  height: 40px;
}

.Form-Container .card i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.Form-Container .mobile-login {
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.Form-Container .mobile-login:hover {
  color: #2194f3a8 !important;
}

.react-tel-input .flag-dropdown {
  background-color: white !important;
  border-radius: 0 !important;
  border: 0 !important;
}

.react-tel-input .selected-flag .arrow {
  left: 23px !important;
}

.react-tel-input .selected-flag {
  width: 43px !important;
  border-right: 1px solid #00000021 !important;
}

.react-tel-input input[type="tel"],
.react-tel-input input[type="text"] {
  border: 0 !important;
  height: 40px !important;
  outline: none !important;
}

.idle-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.idled {
  text-align: center;
  width: 400px;
  color: red;
}
