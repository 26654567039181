.conference-main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 4;
  }
  
  .conference-main-container > button {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    border-radius: 35px;
    width: 185px;
    box-shadow: 0px 0px 6px 2px #c5c5c5;
  }
  
  .conference-main-container > button *:not(:last-child) {
    margin-right: 5px;
  }
  
  .conference-main-container > *:not(:last-child) {
    margin-bottom: 10px;
  }

  .download-cls {
    width: 100px !important;
    background-color: black;
    padding: 0px;
  }