.App-Container {
	overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;

    /* border: 5px solid red; */
}

.App-Container iframe {
    height: 100vh;
    border: none;
}

.App-Row {
    display: flex;
    height: 100%;
    position: relative;
}


.App-Row .TabList-Panel {
    display: flex;
    flex-direction: column;
    /* grid-row-gap: 10px; */
    flex: 1;
    max-width: 350px;
    min-width: 350px;
    position: relative;

    /* border: 5px solid blue; */
    background-color: #f0f2f58a;
}

.App-Row .TabList-Panel>div {
    margin-bottom: 10px;
    margin-top: 10px;
}

.App-Row .TabList-Panel>div:last-child {
    margin-bottom: 0px;
}

.App-Row .TabList-Panel :last-child {
    margin-bottom: unset;
}


.App-Row .Main-Panel {
    flex: 1;
    position: relative;
    height: 100%;

    display: flex;
    flex-direction: column;

    /* border: 5px solid red; */
}

.App-Row .CCM-Panel {
    /* flex: 1; */
    display: inline-flex;
    /* max-width: 350px; */
    position: relative;
    height: 100%;
    border-left: 1px solid rgba(0,0,0,0.12);
}

.App-Row .Document-Panel {
    /* flex: 1; */
    /* display: inline-flex; */
    /* max-width: 350px; */
    position: relative;
    height: 100%;
    border-left: 1px solid rgba(0,0,0,0.12);
}

.offline-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    z-index: 1;
    background: red;
    animation: .5s ease-out 0s 1 slideInFromTop;
}

.offline-container * {
    color: #FFFFFF;
    font-size: 14px;
}

@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
}

.version-container-2 {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 5px;
    z-index: 5;
}

.version-text {
    font-size: 12px;
    font-style: italic;
}
