.selection-container {
    border-radius: 30px;
    display: grid;
    /* flex-direction: row; */
    /* justify-content: flex-start; */
    /* flex-wrap: nowrap; */
    background: #ffffff;
    width: 320px;
    padding: 15px;
}

.forward-selection-table {
    display: grid;
    grid-template-columns: 60%;
    padding-left: 30px;
  }

.forward-selection-table label {
    margin: 0px;
}